export function Lab({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.36927 3.77344H14.6293C15.0143 3.77344 15.2251 4.21344 14.9868 4.51594L13.7493 6.0651V9.8876L19.0659 16.9734C19.5151 17.5784 19.0843 18.4401 18.3326 18.4401H3.66593C2.91427 18.4401 2.48343 17.5784 2.9326 16.9734L8.24927 9.8876V6.0651L7.01177 4.51594C6.77343 4.21344 6.98427 3.77344 7.36927 3.77344ZM16.4993 16.6068L11.9159 10.4926V5.60677H10.0826V10.4926L5.49927 16.6068H16.4993Z"
      />
    </svg>
  );
}
