import { useRef, DragEvent, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { ArrowLeft } from "../../../../assets/icons/ArrowLeft";
import { ExternalLink } from "../../../../assets/icons/ExternalLink";
import {
  usePatientInfo,
  usePatientMonitoringInfo,
} from "../../../../hooks/queries/patients";
import { getShortFormattedDate } from "../../../../utils/dateFormatter";
import { PatientCard, PatientMenuCard } from "../PatientMenuCard";
import {
  EditButton,
  ExpandButton,
  EditControlsButton,
  PatientLateralMenu,
  EditButtonContainer,
  EditControlsContainer,
  PatientLateralMenuCardContainer,
} from "./PatientLateralMenu.styles";

type PatientLateralMenuDesktopProps = {
  open: boolean;
  onExpandClick: () => void;
  patientId: string | undefined;
};

type OrderChangerPair = {
  oldOrder: number;
  newOrder: number;
};

export function PatientLateralMenuDesktop({
  open,
  patientId,
  onExpandClick,
}: PatientLateralMenuDesktopProps) {
  const disableDrag = true;
  const menuRef = useRef<HTMLDivElement>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [patientCards, setPatientCards] = useState<PatientCard[]>([]);

  const [lastPatientCards, setLastPatientCards] = useState<PatientCard[]>(
    [] as PatientCard[]
  );

  const { patient } = usePatientInfo({ patientId });
  const { patientMonitoringInfo } = usePatientMonitoringInfo({ patientId });

  useEffect(() => {
    if (!patient && !patientMonitoringInfo) return;

    setPatientCards([
      {
        order: 1,
        active: true,
        name: "DOB",
        description: patient.birthDate
          ? getShortFormattedDate(patient.birthDate)
          : "-",
      },
      {
        order: 1,
        active: true,
        name: "Hospital ID",
        description: patient?.hospitalId || "-",
      },
      {
        order: 2,
        active: true,
        name: "Email",
        description: patient?.user?.email || "-",
      },
      {
        order: 3,
        active: true,
        name: "Last Review",
        description: patientMonitoringInfo?.lastestReview || "-",
      },
      {
        order: 4,
        active: true,
        name: "Last Reviewed By",
        description: patientMonitoringInfo?.lastestReviewedBy || "-",
      },
      {
        order: 5,
        active: true,
        name: "Phone",
        description: patient?.user?.phoneNumber || "-",
      },
      {
        order: 6,
        active: true,
        name: "Hospital",
        description: patientMonitoringInfo?.hospitalName || "-",
      },
      {
        order: 7,
        active: true,
        name: "Last Used",
        description: patient.user?.lastUsed || "-",
      },
    ]);
  }, [patient, patientMonitoringInfo]);

  const saveLastPatiendCardsConfig = () => {
    setLastPatientCards([...patientCards]);
  };

  const handleOnEditClick = () => {
    saveLastPatiendCardsConfig();
    setIsEditing(true);
  };

  const getOrdersPairs = () => {
    const decimalBase = 10;

    const cards: NodeListOf<HTMLDivElement> =
      document.querySelectorAll("[dragging]");

    const cardsHeight = cards[0]?.clientHeight;

    const oldNewOrderArr: OrderChangerPair[] = [];

    if (cardsHeight) {
      cards.forEach((card) => {
        const oldOrderText = card.getAttribute("order");

        if (oldOrderText) {
          const oldOrder = parseInt(oldOrderText, decimalBase);
          const yPosition = card.offsetTop - card.scrollTop + card.clientTop;
          const newOrder = Math.floor(yPosition / cardsHeight) + 1;

          oldNewOrderArr.push({ oldOrder, newOrder });
        }
      });
    }

    return oldNewOrderArr;
  };

  const updateCardsOrderByPosition = () => {
    const oldNewOrderArr = getOrdersPairs();

    const newPatientCardsArray = patientCards.map((patientCard) => {
      const newPatientCard = { ...patientCard };

      const orderPair = oldNewOrderArr.find(
        ({ oldOrder }) => oldOrder === patientCard.order
      );

      if (orderPair) {
        newPatientCard.order = orderPair.newOrder;
      }

      return newPatientCard;
    });

    setPatientCards(newPatientCardsArray);
  };

  const handleOnSaveEditionClick = () => {
    setIsEditing(false);
    updateCardsOrderByPosition();
  };

  const resetLastCardConfig = () => {
    setPatientCards(lastPatientCards);
  };

  const handleOnCancelEditionClick = () => {
    resetLastCardConfig();
    setIsEditing(false);
  };

  const getNextCard = (currentCardPosY: number) => {
    let result: HTMLDivElement | undefined;

    const cards: NodeListOf<HTMLDivElement> =
      document.querySelectorAll("[dragging='false']");

    cards.forEach((card) => {
      const isNotVisibleCard = card.className.includes("isVisible-false");
      if (isNotVisibleCard) return;

      const cardBox = card.getBoundingClientRect();
      const cardBoxCenterY = cardBox.y + cardBox.height / 2;

      const currentCardIsOverOrInsideThisCard =
        currentCardPosY >= cardBoxCenterY;

      if (currentCardIsOverOrInsideThisCard) result = card;
    });

    return result;
  };

  const handleOnDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Avoid system not-alowed cursor

    const nextCard = getNextCard(event.clientY);

    const dragging: HTMLDivElement | null =
      document.querySelector("[dragging='true']");

    if (dragging) {
      if (nextCard) {
        nextCard.insertAdjacentElement("afterend", dragging);
      } else {
        menuRef.current?.prepend(dragging);
      }
    }
  };

  const cardsOrderComparer = (a: PatientCard, b: PatientCard) => {
    if (a.order === b.order) return 0;

    return a.order > b.order ? 1 : -1;
  };

  const handleOnCardButtonClick = (card: PatientCard) => {
    const newPatientCard: PatientCard = { ...card, active: !card.active };

    const activeCards: PatientCard[] = [];
    const inactiveCards: PatientCard[] = [];

    patientCards.forEach((patientCard) => {
      const isIgnoringCardToChange = patientCard.order !== card.order;

      if (isIgnoringCardToChange) {
        if (patientCard.active) activeCards.push(patientCard);
        else inactiveCards.push(patientCard);
      }
    });

    const newCards = [...activeCards, newPatientCard, ...inactiveCards];

    const newOrderedCards = newCards.map((card, idx) => {
      const order = idx + 1;
      return { ...card, order };
    });

    setPatientCards(newOrderedCards);
  };

  return (
    <PatientLateralMenu isLateralPatientMenuOpenOnDesktop={open}>
      {!disableDrag && !isEditing && (
        <EditButtonContainer>
          <EditButton onClick={handleOnEditClick}>
            <ExternalLink />
          </EditButton>
        </EditButtonContainer>
      )}

      <PatientLateralMenuCardContainer
        ref={menuRef}
        isLateralPatientMenuOpenOnDesktop={open}
      >
        {patientCards.sort(cardsOrderComparer).map((card) => (
          <PatientMenuCard
            key={uuidv4()}
            card={card}
            isEditing={isEditing}
            onDragOver={handleOnDragOver}
            onCardButtonClick={() => handleOnCardButtonClick(card)}
          />
        ))}
      </PatientLateralMenuCardContainer>

      {isEditing && (
        <EditControlsContainer>
          <EditControlsButton onClick={handleOnSaveEditionClick} primary>
            Save
          </EditControlsButton>

          <EditControlsButton onClick={handleOnCancelEditionClick}>
            Cancel
          </EditControlsButton>
        </EditControlsContainer>
      )}

      {!isEditing && (
        <ExpandButton isExpanded={open} onClick={onExpandClick}>
          <ArrowLeft />
        </ExpandButton>
      )}
    </PatientLateralMenu>
  );
}
