export function Pencil({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1875 19.2523H2.75V15.8148L12.8883 5.67645L16.3258 9.11395L6.1875 19.2523ZM12.8883 8.27062L4.58333 16.5756V17.419H5.42667L13.7317 9.11395L12.8883 8.27062Z"
      />
      <path d="M16.8391 3.01812C16.4816 2.66063 15.9041 2.66063 15.5466 3.01812L13.8691 4.69562L17.3066 8.13312L18.9841 6.45563C19.3416 6.09812 19.3416 5.52063 18.9841 5.16313L16.8391 3.01812Z" />
    </svg>
  );
}
