import { useState, DragEvent, useEffect, useRef } from "react";

import { DragHandle } from "../../../../assets/icons/DragHandle";
import { Minus } from "../../../../assets/icons/Minus";
import { Plus } from "../../../../assets/icons/Plus";
import {
  GrabButton,
  PatientMenuCardContainer,
  PatientMenuCardContent,
  PatientMenuCardControls,
  VisibilityButton,
} from "./PatientMenuCard.styles";

export type PatientCard = {
  name: string;
  description: string;
  order: number;
  active: boolean;
};

type PatientMenuCardProps = {
  card: PatientCard;
  isEditing: boolean;
  onDragOver: (event: DragEvent<HTMLDivElement>) => void;
  onCardButtonClick: () => void;
};

export function PatientMenuCard({
  card,
  isEditing,
  onDragOver,
  onCardButtonClick,
}: PatientMenuCardProps) {
  const isActive = card.active;
  const cardRef = useRef<HTMLDivElement>(null);

  const [isOverGrab, setIsOverGrab] = useState(false);

  if (!isEditing && !isActive) return null;

  function setDraggingOfCardRef(active: boolean) {
    cardRef.current?.setAttribute("dragging", String(active));
  }

  useEffect(() => {
    setDraggingOfCardRef(false);
    cardRef.current?.setAttribute("order", card.order.toString());
  }, []);

  const handleOnDragStart = () => {
    const isValidDragging = isEditing && isOverGrab;

    if (!isValidDragging) return;

    setDraggingOfCardRef(true);
  };

  const handleOnDragEnd = () => {
    if (isEditing) setDraggingOfCardRef(false);
  };

  const handleOnDragOver = (event: DragEvent<HTMLDivElement>) => {
    if (isEditing) onDragOver(event);
  };

  const activeOverGrab = () => setIsOverGrab(true);

  const inactiveOverGrab = () => setIsOverGrab(false);

  return (
    <PatientMenuCardContainer
      ref={cardRef}
      isVisible={isActive}
      onDragEnd={handleOnDragEnd}
      onDragStart={handleOnDragStart}
      onDragOver={handleOnDragOver}
      draggable={isOverGrab && isActive && isEditing}
    >
      <PatientMenuCardContent>
        <p>
          <span>{card.name}</span>
        </p>
        <button type="button">{card.description}</button>
      </PatientMenuCardContent>

      {isEditing && (
        <PatientMenuCardControls>
          {isActive && (
            <GrabButton
              onMouseEnter={activeOverGrab}
              onMouseLeave={inactiveOverGrab}
            >
              <DragHandle />
            </GrabButton>
          )}

          <VisibilityButton isActive={isActive} onClick={onCardButtonClick}>
            {isActive ? <Minus /> : <Plus />}
          </VisibilityButton>
        </PatientMenuCardControls>
      )}
    </PatientMenuCardContainer>
  );
}
