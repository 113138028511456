import dayjs from "dayjs";

export const dateFilterOptions = [
  { label: "24H", value: dayjs().subtract(1, "day") },
  { label: "48H", value: dayjs().subtract(2, "day") },
  { label: "7D", value: dayjs().subtract(7, "day") },
  { label: "1M", value: dayjs().subtract(1, "month") },
  { label: "2M", value: dayjs().subtract(2, "month") },
  { label: "3M", value: dayjs().subtract(3, "month") },
  { label: "6M", value: dayjs().subtract(6, "month") },
  { label: "9M", value: dayjs().subtract(9, "month") },
  { label: "12M", value: dayjs().subtract(12, "month") },
];
