import { useEffect, useMemo } from "react";

import {
  IPatientListVariants,
  PatientListTableContent,
} from "../../@types/Patient";
import { AlertsCounter } from "../../components/AlertsCounter";
import { DropdownRouter } from "../../components/DropdownRouter";
import { patientListVariants } from "../../constants/patientListVariants";
import { useFlagPatient } from "../../hooks/mutations/patients";
import { usePatientList } from "../../hooks/queries/patients";
import useIsMobile from "../../hooks/useIsMobile";
import { PageLayout } from "../../layout/components/PageLayout";
import {
  MobilePageTitleContainer,
  PageTitle,
} from "../../layout/components/PageTitle";
import { mixpanelActions } from "../../utils/mixpanel";
import { capitalizeFirstLetter } from "../../utils/stringFormatter";
import { PatientListTable } from "./components/PatientListTable";
import { FlagPatientButton } from "./components/PatientListTable/components/FlagPatientButton";
import { PatientListTableContainer } from "./PatientList.styles";

export function PatientListHeader({ variant }: IPatientListVariants) {
  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (variant === patientListVariants.ACTIVE) {
      mixpanelActions.track("Patient List Page: Active");
    } else {
      mixpanelActions.track("Patient List Page: Archived");
    }
  }, [variant]);

  if (isMobile) {
    return (
      <MobilePageTitleContainer>
        <PageTitle>Patients</PageTitle>

        <DropdownRouter childRoutesOf="PatientList" />
      </MobilePageTitleContainer>
    );
  }

  return <PageTitle>{capitalizeFirstLetter(variant)} patients</PageTitle>;
}

export function PatientList({ variant }: IPatientListVariants) {
  document.title = `${capitalizeFirstLetter(variant)} Patients - patientMpower`;

  const { patients, isLoading } = usePatientList({ variant });
  const mutate = useFlagPatient();

  const parsePatientsToTable = useMemo(() => {
    if (!patients) return [];

    return patients.map((patient: any) => {
      return {
        id: patient.id,
        name: patient.name,
        flagged: patient.flagged,
        lastUsed: patient.lastUsed,
        hospitalId: patient.hospitalId,
        signUpDate: patient.signUpDate,
        lastReviewedBy: patient.lastReviewedBy,
        alerts:
          patient.alerts !== "-" ? (
            <AlertsCounter>{patient.alerts}</AlertsCounter>
          ) : (
            "-"
          ),
        action: (
          <div>
            <FlagPatientButton
              onClick={(e) => {
                e.stopPropagation();

                mutate({ patientId: patient.id, flagged: !patient.flagged });
              }}
              flagged={patient.flagged}
            />
          </div>
        ),
      } as PatientListTableContent;
    });
  }, [patients]);

  return (
    <PageLayout>
      <PatientListHeader variant={variant} />

      <PatientListTableContainer>
        <PatientListTable
          variant={variant}
          isLoading={isLoading}
          content={parsePatientsToTable}
        />
      </PatientListTableContainer>
    </PageLayout>
  );
}
