import Cookies from "js-cookie";
import { createContext, ReactNode, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ILogin, ILoginResponse } from "../@types/Login";
import { COOKIE_AUTH_NAME } from "../constants/authConstants";
import { useToken } from "../hooks/useToken";
import { authService } from "../services/authService";

type IAuthContextData = {
  authenticate: (credentials: ILogin) => Promise<ILoginResponse>;
  isAuthenticated: boolean;
  signOut: () => void;
  token: string | undefined;
};

type IAuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext({} as IAuthContextData);

export function AuthProvider({ children }: IAuthProviderProps) {
  const { token, setToken } = useToken();

  const navigate = useNavigate();

  async function authenticate({ email, password }: ILogin) {
    const responseFromAPI = await authService.authenticate({ email, password });

    const { token } = responseFromAPI;

    if (token) {
      Cookies.set(COOKIE_AUTH_NAME, token, { expires: 60 });
      setToken(token);

      navigate("/");
    }

    return responseFromAPI;
  }

  function signOut() {
    Cookies.remove(COOKIE_AUTH_NAME);

    window.location.assign("/login");
  }

  const valuesToShare = useMemo(
    () => ({
      token,
      authenticate,
      signOut,
      isAuthenticated: Boolean(token),
    }),
    [token]
  );

  return (
    <AuthContext.Provider value={valuesToShare}>
      {children}
    </AuthContext.Provider>
  );
}
