import { useQuery } from "@tanstack/react-query";

import {
  GetPatientMeasurementProps,
  IPatientMeasurementAPIResponse,
  MeasurementTypes,
} from "../../@types/Measurements";
import { measurementsService } from "../../services/measurementsService";
import { getLongFormattedDate } from "../../utils/dateFormatter";
import { roundToX } from "../../utils/unitOperations";
import { usePatientPage } from "../usePatientPage";

export function useMeasurement({
  type,
  patientId,
  zoomedVersion,
  fromDate,
  toDate,
}: GetPatientMeasurementProps) {
  const { globalDateFilter } = usePatientPage();

  const parseDataAndGetMainMeasurementValues = ({
    data,
    measurementType,
  }: {
    data: IPatientMeasurementAPIResponse[];
    measurementType: MeasurementTypes;
  }) => {
    if (!data.length) {
      return {
        measurements: [],
        mainMeasurementValues: {
          min: null,
          max: null,
          avg: null,
        },
      };
    }

    let min = data[0][measurementType];
    let max = data[0][measurementType];

    let sum = 0;

    const measurements = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
      const currentData = data[i];

      min = Math.min(min, currentData[measurementType]);
      max = Math.max(max, currentData[measurementType]);

      sum += data[i][measurementType];

      const measurementParsedObject = {
        unformattedTime: currentData.time,
        [measurementType]: roundToX(currentData[measurementType]),
        time: getLongFormattedDate(currentData.time),
      };

      const spirometryTestIds = currentData.spirometry_test_ids;
      if (currentData.spirometry_test_ids) {
        measurementParsedObject.spirometryTestIds = spirometryTestIds;
      }

      measurements.push(measurementParsedObject);
    }

    const avg = roundToX(sum) / data.length;

    return {
      measurements,
      mainMeasurementValues: {
        min: roundToX(min),
        max: roundToX(max),
        avg: roundToX(avg),
      },
    };
  };

  const { data, isError, isInitialLoading, isFetching } = useQuery(
    [
      `measurement-${type}-${patientId}-${zoomedVersion}`,
      type,
      toDate,
      fromDate,
    ],
    async () => {
      const { data } = await measurementsService.getPatientMeasurement({
        type,
        patientId,
        toDate: toDate || globalDateFilter.toDate.format("YYYY-MM-DD"),
        fromDate: fromDate || globalDateFilter.fromDate.format("YYYY-MM-DD"),
      });

      return parseDataAndGetMainMeasurementValues({
        data,
        measurementType: type,
      });
    },
    { refetchOnMount: "always", enabled: type !== "-" }
  );

  return {
    measurement: data?.measurements,
    mainMeasurementValues: data?.mainMeasurementValues,
    isError,
    isFetching,
    isInitialLoading,
  };
}
