import mixpanel from "mixpanel-browser";

import { IRoutePattern } from "../../../@types/Route";
import { MobileAvatar } from "../../../components/UserSettings";
import { useLeftPanel } from "../../../hooks/useLeftPanel";
import { Link } from "../Link";
import {
  Container,
  BothDevicesSection,
  DisabledOverlay,
  Divider,
  IconContainer,
  List,
  ListItem,
  MobileSection,
} from "./LeftPanelMenu.styles";

interface ILeftPanelMenuItemProps extends IRoutePattern {
  onClick: () => void;
}
type ILeftPanelMenuProps = {
  items: IRoutePattern[];
  onLeftPanelMenuItemClick: () => void;
};

export function LeftPanelMenuItem({
  path,
  name,
  Icon,
  context,
  onClick,
  disabled,
}: ILeftPanelMenuItemProps) {
  const handleOnClickOverListItem = () => {
    if (disabled) return;

    mixpanel.track(`${context}: Sub-Nav ${name}`);
    onClick();
  };

  return (
    <ListItem
      onClick={handleOnClickOverListItem}
      disabled={disabled}
      key={`${Math.random()}-${path}`}
    >
      {disabled && <DisabledOverlay role="link" aria-disabled />}

      <Link to={path}>
        <IconContainer>
          <Icon width="22px" height="22px" />
        </IconContainer>

        <p>{name}</p>
      </Link>
    </ListItem>
  );
}

export function LeftPanelMenu({
  items,
  onLeftPanelMenuItemClick,
}: ILeftPanelMenuProps) {
  const { isMobileLeftPanelMenuOpen, setIsMobileUserSettingsMenuOpen } =
    useLeftPanel();

  const renderLeftBarItems = () => {
    return items.map(({ name, path, Icon, disabled, context }) => (
      <LeftPanelMenuItem
        name={name}
        key={`${Math.random()}-${path}`}
        path={path}
        context={context}
        onClick={onLeftPanelMenuItemClick}
        disabled={disabled}
        Icon={Icon}
      />
    ));
  };

  const handleMobileAvatarClick = () => {
    setIsMobileUserSettingsMenuOpen(true);
  };

  return (
    <Container mobileLeftPanelMenuOpen={isMobileLeftPanelMenuOpen}>
      <BothDevicesSection>
        <List>{renderLeftBarItems()}</List>
      </BothDevicesSection>

      <MobileSection>
        <Divider />
        <MobileAvatar onClick={handleMobileAvatarClick} />
      </MobileSection>
    </Container>
  );
}
