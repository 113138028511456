import { styled } from "@patientmpower/spiro";

import {
  closedPatientLateralMenuCardContainerWidth,
  expandedPatientLateralMenuCardContainerWidth,
} from "./components/PatientLateralMenu/PatientLateralMenu.styles";

export const ChartsContainer = styled("div", {
  marginTop: "6px",

  gap: "4px",

  display: "flex",
  flexWrap: "wrap",

  "@sm": {
    padding: "28px 44px",

    justifyContent: "center",
  },
});

export const StyledSection = styled("section", {
  scrollMarginTop: 158,
  marginTop: 64,

  "&:first-child": { marginTop: 0 },

  "@sm": {
    marginTop: 0,
  },
});

export const LoadingOverlay = styled("div", {
  position: "absolute",

  top: 0,
  left: 0,

  width: "100%",
  height: "100%",

  zIndex: 1500,

  backgroundColor: "white",
  opacity: 0.64,
});

export const MobilePatientPageTitleContainer = styled("div", {
  width: "100%",
});

export const PatientPageContainer = styled("div", {
  display: "flex",
});

export const PatientPageContent = styled("div", {
  transition: "width .4s",

  width: `calc(100% - ${closedPatientLateralMenuCardContainerWidth}px)`,

  variants: {
    isLateralPatientMenuOpenOnDesktop: {
      true: {
        transition: "width .4s",

        width: `calc(100% - ${expandedPatientLateralMenuCardContainerWidth}px)`,
      },
    },
  },

  "@sm": {
    width: "100%",
  },
});
