import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { IPatientAlertInfo } from "../../../../@types/Patient";
import { ReactComponent as ErrorCheckingAlertsImage } from "../../../../assets/images/error-checking-alerts.svg";
import { ReactComponent as NoAlertTriggeredImage } from "../../../../assets/images/no-alerts-triggered.svg";
import { usePatientAlerts } from "../../../../hooks/queries/patients";
import useIsMobile from "../../../../hooks/useIsMobile";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { ImageContainer, TableContainer } from "./AlertsSection.styles";
import { PatientAlertsTable } from "./components/PatientAlertsTable";

export function AlertsSection() {
  const { patientId } = useParams();
  const { isMobile } = useIsMobile();

  const { alerts, isFetching, isError } = usePatientAlerts({
    patientId,
  });

  const parseAlertsToTable = useMemo(() => {
    if (!alerts) return [];

    return alerts.map((currentAlert: IPatientAlertInfo) => ({
      ...currentAlert,
      timeFrame: currentAlert.timeFrame || "Any reading",
      alertStatus: currentAlert.resolved || "Unresolved",
    }));
  }, [alerts]);

  if (isError) {
    return (
      <>
        {!isMobile && <PageTitle>Alerts</PageTitle>}

        <ImageContainer>
          <ErrorCheckingAlertsImage />
          Error checking alerts, please try again. If the issue continues
          contact support.
        </ImageContainer>
      </>
    );
  }

  return (
    <>
      {!isMobile && <PageTitle>Alerts</PageTitle>}

      {alerts && !alerts.length ? (
        <ImageContainer>
          <NoAlertTriggeredImage />
          No alerts triggered yet. Ensure thresholds are correct in Manage
          alerts.
        </ImageContainer>
      ) : (
        <TableContainer>
          <PatientAlertsTable
            isLoading={isFetching}
            content={parseAlertsToTable}
          />
        </TableContainer>
      )}
    </>
  );
}
