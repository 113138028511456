import { styled } from "@patientmpower/spiro";

export const ContentContainer = styled("div", {
  width: "100%",
  height: "100%",

  padding: "62px 0 0 88px",

  backgroundColor: "$gray-20",
  overflowY: "scroll",

  "@sm": {
    padding: "62px 0 0 0",
  },
});

export const NavContentContainer = styled("div", {
  display: "flex",
  flexGrow: 1,

  "@sm": {
    width: "100%",
    justifyContent: "flex-end",
  },
});

export const TopPanelItemsContainerDesktop = styled("div", {
  display: "flex",

  "@sm": {
    display: "none",
  },
});
