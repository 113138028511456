import { styled } from "@patientmpower/spiro";
import * as HoverCard from "@radix-ui/react-hover-card";

export const ListBlowItem = styled("div", {
  width: "212px",
  padding: "8px 24px 8px 16px",

  borderRadius: "8px",

  fontFamily: "$openSans",
  fontSize: "$text-md",

  color: "$gray-80",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  cursor: "auto",

  "&:hover": {
    background: "$gray-30",
  },
});

export const ListBlowHoverCardContent = styled(HoverCard.Content, {
  maxWidth: "216px",

  marginTop: "8px",

  backgroundColor: "$charcoal",
  overflowWrap: "break-word",

  color: "$white",
  fontFamily: "$openSans",

  fontWeight: 500,
  fontSize: "$text-md",
  lineHeight: "16px",

  borderRadius: "4px",

  padding: "18px 32px",

  p: {
    marginBottom: "6px",

    "&:last-child": {
      marginBottom: 0,
    },
  },
});
