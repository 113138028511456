import { QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import ContextCompose from "./components/ContextComponse/ContextCompose";
import { queryClient } from "./config/query";
import { AuthProvider } from "./contexts/AuthContext";
import { ChildRoutesProvider } from "./contexts/ChildRoutesContext";
import { LeftPanelProvider } from "./contexts/LeftPanelContext";
import { ModalProvider } from "./contexts/ModalContext";
import { PatientPageProvider } from "./contexts/PatientPageContext";
import { UserPreferencesProvider } from "./contexts/UserPreferencesContext";

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ContextCompose
        components={[
          AuthProvider,
          ChildRoutesProvider,
          LeftPanelProvider,
          ModalProvider,
          PatientPageProvider,
          UserPreferencesProvider,
        ]}
      >
        <App />
      </ContextCompose>
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById("root") as HTMLElement
);
