export function Vitals({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4167 2.75H4.58333C3.575 2.75 2.75 3.575 2.75 4.58333V17.4167C2.75 18.425 3.575 19.25 4.58333 19.25H17.4167C18.425 19.25 19.25 18.425 19.25 17.4167V4.58333C19.25 3.575 18.425 2.75 17.4167 2.75ZM17.4167 17.4167H4.58333V4.58333H17.4167V17.4167ZM8.25 9.16667H6.41667V15.5833H8.25V9.16667ZM10.0833 6.41667H11.9167V15.5833H10.0833V6.41667ZM15.5833 11.9167H13.75V15.5833H15.5833V11.9167Z"
      />
    </svg>
  );
}
