import { useMemo } from "react";

import { PatientConditionTypes } from "../@types/Patient";
import { measurementsConfigs } from "../constants/measurements";

type MeasurementsByConditionProps = {
  condition?: PatientConditionTypes;
};

type MeasurementsConfig = {
  type: string;
  title: string;
  unit?: string;
  color?: string;
  category?: string;
};

type MeasurementsByConditionReturn = {
  measurements: MeasurementsConfig[];
  labsResult: MeasurementsConfig[];
  spirometryResults: MeasurementsConfig[];
};

export function useMeasurementsByCondition({
  condition,
}: MeasurementsByConditionProps): MeasurementsByConditionReturn {
  const measurements = useMemo<MeasurementsConfig[]>(() => {
    switch (condition) {
      case "covid-19":
        return [
          measurementsConfigs.PULSE_OX,
          measurementsConfigs.TEMPERATURE,
          measurementsConfigs.BREATHLESSNESS,
          measurementsConfigs.DIARRHOEA,
          measurementsConfigs.HEART_RATE,
        ];

      case "ipf":
        return [
          measurementsConfigs.DYSPNEA,
          measurementsConfigs.PULSE_OX,
          measurementsConfigs.TEMPERATURE,
          measurementsConfigs.SYSTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.DIASTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.HEART_RATE,
          measurementsConfigs.WEIGHT,
          measurementsConfigs.HEIGHT,
        ];

      case "lung-transplant":
        return [
          measurementsConfigs.DYSPNEA,
          measurementsConfigs.PULSE_OX,
          measurementsConfigs.TEMPERATURE,
          measurementsConfigs.WEIGHT,
          measurementsConfigs.SYSTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.DIASTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.BLOOD_GLUCOSE,
          measurementsConfigs.CNI_TROUGH,
        ];

      case "cystic-fibrosis":
        return [
          measurementsConfigs.PULSE_OX,
          measurementsConfigs.SPUTUM_VOLUME,
          measurementsConfigs.WEIGHT,
          measurementsConfigs.HEIGHT,
        ];

      case "copd":
        return [
          measurementsConfigs.PULSE_OX,
          measurementsConfigs.HEART_RATE,
          measurementsConfigs.OXYGEN_FLOW_RATE,
          measurementsConfigs.TEMPERATURE,
          measurementsConfigs.SYSTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.DIASTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.BLOOD_GLUCOSE,
          measurementsConfigs.WEIGHT,
          measurementsConfigs.HEIGHT,
          measurementsConfigs.RESPIRATORY_RATE,
          measurementsConfigs.ROCKWOOD_INDEX,
          measurementsConfigs.BARTHEL_INDEX,
        ];

      case "asthma":
        return [
          measurementsConfigs.PULSE_OX,
          measurementsConfigs.WEIGHT,
          measurementsConfigs.HEIGHT,
        ];

      case "vasculitis":
        return [
          measurementsConfigs.WEIGHT,
          measurementsConfigs.SYSTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.DIASTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.PULSE_OX,
          measurementsConfigs.TEMPERATURE,
        ];

      case "kidney-transplant":
        return [
          measurementsConfigs.SYSTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.DIASTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.CNI_TROUGH,
          measurementsConfigs.CREATININE,
          measurementsConfigs.GFR,
          measurementsConfigs.WHITE_BLOOD_CELLS,
          measurementsConfigs.WEIGHT,
          measurementsConfigs.TEMPERATURE,
          measurementsConfigs.PULSE_OX,
        ];

      case "prostate-cancer":
        return [
          measurementsConfigs.PSA,
          measurementsConfigs.SYSTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.DIASTOLIC_BLOOD_PRESSURE,
        ];

      case "hypertension":
        return [
          measurementsConfigs.WEIGHT,
          measurementsConfigs.SYSTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.DIASTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.PULSE_OX,
          measurementsConfigs.TEMPERATURE,
        ];

      case "haemodialysis":
        return [
          measurementsConfigs.WEIGHT,
          measurementsConfigs.SYSTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.DIASTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.PHOSPHATE,
          measurementsConfigs.PULSE_OX,
          measurementsConfigs.TEMPERATURE,
        ];

      default:
        return [{ title: "N/A", type: "-" }];
    }
  }, [condition]);

  const labsResult = useMemo<MeasurementsConfig[]>(() => {
    switch (condition) {
      case "covid-19":
        return [{ title: "N/A", type: "-" }];

      case "ipf":
        return [measurementsConfigs.HEMOGLOBIN];

      case "lung-transplant":
        return [
          measurementsConfigs.HEMOGLOBIN,
          measurementsConfigs.BLOOD_GLUCOSE,
        ];

      case "cystic-fibrosis":
        return [{ title: "N/A", type: "-" }];

      case "copd":
        return [measurementsConfigs.BLOOD_GLUCOSE];

      case "asthma":
        return [{ title: "N/A", type: "-" }];

      case "vasculitis":
        return [measurementsConfigs.HEMOGLOBIN];

      case "kidney-transplant":
        return [
          measurementsConfigs.CREATININE,
          measurementsConfigs.HEMOGLOBIN,
          measurementsConfigs.WHITE_BLOOD_CELLS,
        ];

      case "prostate-cancer":
        return [measurementsConfigs.PSA];

      case "hypertension":
        return [
          measurementsConfigs.WEIGHT,
          measurementsConfigs.SYSTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.DIASTOLIC_BLOOD_PRESSURE,
          measurementsConfigs.PULSE_OX,
          measurementsConfigs.TEMPERATURE,
        ];

      case "haemodialysis":
        return [measurementsConfigs.PHOSPHATE, measurementsConfigs.HEMOGLOBIN];

      default:
        return [{ title: "N/A", type: "-" }];
    }
  }, [condition]);

  const spirometryResults = useMemo<MeasurementsConfig[]>(() => {
    switch (condition) {
      case "covid-19":
        return [{ title: "N/A", type: "-" }];

      case "ipf":
        return [
          measurementsConfigs.FVC,
          measurementsConfigs.FVC_PERCENT_PREDICTED,
          measurementsConfigs.FEV_1,
          measurementsConfigs.FEV1_PERCENT_PREDICTED,
        ];

      case "lung-transplant":
        return [
          measurementsConfigs.FEV_1,
          measurementsConfigs.FEV25_75,
          measurementsConfigs.FVC,
        ];

      case "cystic-fibrosis":
        return [
          measurementsConfigs.FEV_1,
          measurementsConfigs.FEV1_PERCENT_PREDICTED,
          measurementsConfigs.FEV1_FVC,
        ];

      case "copd":
        return [
          measurementsConfigs.FVC,
          measurementsConfigs.FVC_PERCENT_PREDICTED,
          measurementsConfigs.FEV_1,
          measurementsConfigs.FEV1_PERCENT_PREDICTED,
          measurementsConfigs.FEV1_FVC,
        ];

      case "asthma":
        return [
          measurementsConfigs.FEV_1,
          measurementsConfigs.FEV1_PERCENT_PREDICTED,
          measurementsConfigs.FEV1_FVC,
        ];

      case "vasculitis":
        return [{ title: "N/A", type: "-" }];

      case "kidney-transplant":
        return [{ title: "N/A", type: "-" }];

      case "prostate-cancer":
        return [{ title: "N/A", type: "-" }];

      case "hypertension":
        return [{ title: "N/A", type: "-" }];

      case "haemodialysis":
        return [{ title: "N/A", type: "-" }];

      default:
        return [{ title: "N/A", type: "-" }];
    }
  }, [condition]);

  return { measurements, labsResult, spirometryResults };
}
