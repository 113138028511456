import { styled } from "@patientmpower/spiro";

export const ImageContainer = styled("div", {
  overflowY: "overlay",
  maxHeight: "100%",

  zIndex: 1000,

  borderRadius: "8px",

  width: "100%",
  minHeight: "740px",

  border: "1px solid #ECECEC",
  backgroundColor: "$white",

  boxSizing: "border-box",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "10px",
});

export const TableContainer = styled("div", {
  height: 410,
});
