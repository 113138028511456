import { Button, LinkButton, TextField } from "@patientmpower/spiro";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { ILogin } from "../../@types/Login";
import pmpLogoName from "../../assets/images/pmp-logo-name.png";
import { useAuth } from "../../hooks/useAuth";
import {
  Logo,
  Container,
  StyledTile,
  ContentContainer,
  emailTextFieldStyles,
  passwordTextFieldStyles,
  FooterLinksContainerDesktop,
  FooterLinksContainerMobile,
  LoadingOverlay,
  loginButtonStyles,
  ErrorMessage,
} from "./LoginPage.styles";

export function LoginPage() {
  document.title = "Login - patientMpower";

  const [apiFieldErros, setApiFieldErrors] = useState({
    email: "",
    password: "",
  });
  const { authenticate, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [globalError, setGlobalError] = useState("");
  const [capsLockEnabled, setCapsLockEnabled] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const formValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address.")
      .required("Please enter your email address."),
    password: yup.string().required("Please enter your password."),
  });

  const handleOnSubmitForm = async ({ email, password }: ILogin) => {
    setIsLoading(true);

    const responseFromAPI = await authenticate({
      email,
      password,
    });

    const { errors } = responseFromAPI;
    if (errors) {
      setIsLoading(false);
      setGlobalError(errors);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: handleOnSubmitForm,
    // validate: handleOnValidateFields,
    validationSchema: formValidationSchema,
  });

  const footerLinks = [
    { name: "termsAndConditions", label: "Terms & conditions" },
    { name: "privayPolicy", label: "Privacy policy" },
    { name: "contactSupports", label: "Contact support" },
  ];

  const shouldShowErrorMessage = (field: "email" | "password") => {
    return formik.touched[field]
      ? apiFieldErros[field] || formik.errors[field]
      : "";
  };

  const handleCheckCapsLockOnKeyUp = (event: any) => {
    const capsEnabled =
      (event.getModifierState && event.getModifierState("CapsLock")) || false;

    setCapsLockEnabled(capsEnabled);
  };

  const handleResetGlobalErrorFieldOnBlur = (field: "email" | "password") => {
    setApiFieldErrors((state: ILogin) => ({ ...state, [field]: "" }));
  };

  const handleOnClickOverForgotPassword = () => {
    console.log("forgot password click!");
  };

  const handleOnClickOverFooterLinks = (selectedFooterLinkName: string) => {
    console.log("footer link click! -> ", selectedFooterLinkName);
  };

  return (
    <Container>
      <StyledTile>
        {isLoading && <LoadingOverlay />}

        <ContentContainer onSubmit={formik.handleSubmit}>
          <Logo src={pmpLogoName} alt="patientMpower logo" />

          <TextField
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="jane@example.com"
            className={emailTextFieldStyles()}
            errorMessage={shouldShowErrorMessage("email")}
            onBlur={() => handleResetGlobalErrorFieldOnBlur("email")}
          />

          <TextField
            isPassword
            name="password"
            label="Password"
            placeholder="●●●●●●●●●●●●"
            value={formik.values.password}
            onChange={formik.handleChange}
            className={passwordTextFieldStyles()}
            auxiliarButtonLabel="Forgot password?"
            onKeyDown={handleCheckCapsLockOnKeyUp}
            errorMessage={shouldShowErrorMessage("password")}
            onClickAuxiliarButton={handleOnClickOverForgotPassword}
            onBlur={() => handleResetGlobalErrorFieldOnBlur("password")}
            warningMessage={capsLockEnabled ? "Caps lock is on." : undefined}
          />

          {globalError && <ErrorMessage>{globalError}</ErrorMessage>}

          <Button
            type="submit"
            label="Log in"
            isLoading={isLoading}
            className={loginButtonStyles()}
          />
        </ContentContainer>

        <FooterLinksContainerMobile>
          {footerLinks.map((currentLink) => (
            <LinkButton
              size="md"
              color="gray"
              fontWeight="bold"
              hoverColor="secondary"
              label={currentLink.label}
              key={`${Math.random() * 1000}-${currentLink.name}`}
              onClick={() => handleOnClickOverFooterLinks(currentLink.name)}
            />
          ))}
        </FooterLinksContainerMobile>
      </StyledTile>

      <FooterLinksContainerDesktop>
        {footerLinks.map((currentLink) => (
          <LinkButton
            size="md"
            color="gray"
            fontWeight="bold"
            hoverColor="secondary"
            label={currentLink.label}
            key={`${Math.random() * 1000}-${currentLink.name}`}
            onClick={() => handleOnClickOverFooterLinks(currentLink.name)}
          />
        ))}
      </FooterLinksContainerDesktop>
    </Container>
  );
}
