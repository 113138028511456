import { styled } from "@patientmpower/spiro";

export const Button = styled("div", {
  all: "unset",

  height: "30px",
  width: "30px",

  borderRadius: "$round",

  display: "flex",

  alignItems: "center",
  justifyContent: "center",

  cursor: "pointer",

  "&:hover": {
    backgroundColor: "$gray-50",
  },

  variants: {
    active: {
      true: {
        backgroundColor: "$gray-30",
      },
    },
  },
});
