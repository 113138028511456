import { SkeletonLoading } from "@patientmpower/spiro";

export function ChartCardLoading() {
  return (
    <>
      <div style={{ marginBottom: "8px" }}>
        <SkeletonLoading height="22px" width="100px" />
      </div>

      <SkeletonLoading height="22px" />

      <div style={{ marginTop: "16px" }}>
        <SkeletonLoading height="80px" />
      </div>

      <div
        style={{
          marginTop: "16px",
        }}
      >
        <SkeletonLoading height="50px" />
      </div>
    </>
  );
}
