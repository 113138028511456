import { useState } from "react";

import { SelectMeasurementPayloadType } from "../../../../@types/Measurements";
import { MeasurementSection } from "./components/MeasurementSection";
import { SpirometrySection } from "./components/SpirometrySection";
import { Container } from "./MeasurementModal.styles";
import { MeasurementModalProps } from "./MeasurementModal.types";

export function MeasurementModal({
  cardTitle,
  patientId,
  chartConfig,
  measurementType,
}: MeasurementModalProps) {
  const [
    selectedSpirometryMeasurementValue,
    setSelectedSpirometryMeasurementValue,
  ] = useState<SelectMeasurementPayloadType | null>(null);

  return (
    <Container>
      <MeasurementSection
        cardTitle={cardTitle}
        patientId={patientId}
        chartConfig={chartConfig}
        measurementType={measurementType}
        onSelectChartValue={(selectedChartValue) => {
          // Show a snackbar if the spirometry value is undefined
          setSelectedSpirometryMeasurementValue(selectedChartValue);
        }}
      />

      {selectedSpirometryMeasurementValue?.spirometryTestIds && (
        <SpirometrySection
          selectedMeasurement={selectedSpirometryMeasurementValue}
        />
      )}
    </Container>
  );
}
