import { styled, Tile } from "@patientmpower/spiro";

export const ChartValueContainer = styled("div", {
  display: "flex",
  width: "85%",

  justifyContent: "space-between",

  marginTop: "24px",
});

export const Label = styled("p", {
  fontSize: "$text-md",
  lineHeight: "16px",

  color: "$gray-100",
});

export const Value = styled("p", {
  fontWeight: 600,

  fontSize: "$text-lg",
  lineHeight: "22px",

  color: "$gray-100",
});

export const StyledTooltip = styled("div", {
  borderRadius: "4px",
  padding: "12px 16px",

  display: "flex",
  flexDirection: "column",

  backgroundColor: "$charcoal",

  h4: {
    color: "$white",

    fontFamily: "$openSans",
    fontSize: "$text-md",

    lineHeight: "16px",
    marginBottom: "4px",

    fontWeight: 500,

    "&:last-child": {
      marginBottom: "8px",
    },
  },

  p: {
    color: "$gray-60",

    fontWeight: 500,
    fontFamily: "$openSans",
    fontSize: "$text-md",

    lineHeight: "16px",
  },

  variants: {
    singleValue: {
      true: {
        alignItems: "center",
      },
    },
  },
});

export const StyledSubtitle = styled("p", {
  color: "$gray-90",
  marginTop: "8px",

  fontSize: "$text-md",
  lineHeight: "16px",

  fontFamily: "$openSans",
});

export const EmptyStateOverlay = styled("div", {
  // zIndex: 200,
  opacity: 0.64,
  position: "absolute",

  width: "88%",
  height: "70%",

  backgroundColor: "$white",
});

export const StyledLegend = styled("li", {
  listStyleType: "none",

  gap: "8px",
  display: "flex",
  alignItems: "center",

  color: "$gray-90",

  lineHeight: "16px",
  fontSize: "$text-md",
});

export const Circle = styled("div", {
  width: "5px",
  height: "5px",

  borderRadius: "$round",
});

export const StyledTile = styled(Tile, {
  width: "376px",
  height: "340px",

  position: "relative",

  padding: "28px 32px 38px 32px",

  backgroundColor: "$white",
  cursor: "pointer",

  "@md": {
    width: "302px",
    height: "280px",
  },

  "@sm": {
    borderRadius: "$4",
    border: "1px solid $gray-40",
  },

  variants: {
    disabled: {
      true: {
        cursor: "not-allowed",
      },
    },
  },
});

export const ChartTitle = styled("h3", {
  fontSize: "$text-lg",
  fontWeight: 400,

  marginBottom: "2px",

  lineHeight: "22px",
  color: "$black",
});
