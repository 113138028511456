import * as PopoverPrimitive from "@radix-ui/react-popover";

import Dummy from "../../assets/images/dummy.png";
import { useAuth } from "../../hooks/useAuth";
import {
  StyledContent,
  PopoverOption,
  IconButton,
  PopoverContainer,
  UserSettingsMobileContainer,
} from "./UserSettings.style";

const Popover = PopoverPrimitive.Root;

function PopoverContent({ children, ...props }: any) {
  return (
    <PopoverPrimitive.Portal>
      <StyledContent {...props}>{children}</StyledContent>
    </PopoverPrimitive.Portal>
  );
}

export function UserSettingsDesktop() {
  const { signOut } = useAuth();

  const handleSignOutClick = () => {
    signOut();
  };

  return (
    <PopoverContainer>
      <Popover>
        <PopoverPrimitive.Trigger tabIndex={0} asChild>
          <IconButton aria-label="User settings">
            <img src={Dummy} alt="profile" />
          </IconButton>
        </PopoverPrimitive.Trigger>
        <PopoverContent>
          <PopoverOption onClick={handleSignOutClick}>Sign out</PopoverOption>
        </PopoverContent>
      </Popover>
    </PopoverContainer>
  );
}

type IUserSettingsMobileProps = {
  onClick?: () => void;
  imgSource?: string;
};

export function MobileAvatar({
  onClick,
  imgSource = Dummy,
}: IUserSettingsMobileProps) {
  return (
    <UserSettingsMobileContainer type="button" onClick={onClick}>
      <img src={imgSource} alt="Profile" />
      <p>Profile</p>
    </UserSettingsMobileContainer>
  );
}
