import React from "react";
import { NavLink, useParams } from "react-router-dom";

type INavLink = {
  to: string;
  children: React.ReactNode;
};

export function Link({ to, children }: INavLink) {
  const pathSeparator = "/";
  const pathVariableInit = ":";

  const havePathVariable = to.includes(pathVariableInit);

  function getVariablesToPath() {
    let pathWithVariables = to;

    const possiblePathVariables = to
      .split(pathSeparator)
      .filter((possiblePathVariable) => {
        return possiblePathVariable.startsWith(pathVariableInit);
      });

    possiblePathVariables.forEach((possiblePathVariable) => {
      const possibleVariable = possiblePathVariable.replace(
        pathVariableInit,
        ""
      );
      const params = useParams();
      const pathVariable = params[possibleVariable];

      pathWithVariables = pathWithVariables.replace(
        possiblePathVariable,
        pathVariable || possiblePathVariable
      );
    });
    return pathWithVariables;
  }

  const path = havePathVariable ? getVariablesToPath() : to;

  return <NavLink to={path}>{children}</NavLink>;
}
