import { useMutation, useQueryClient } from "@tanstack/react-query";

import { patientService } from "../../services/patientService";

export function useFlagPatient() {
  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    ({
      patientId,
      flagged,
    }: {
      patientId: string | number;
      flagged: boolean;
    }) => patientService.flagPatient({ id: patientId, flagged }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["patient-list-active"]);
        queryClient.refetchQueries(["patient-list-flagged"]);
      },
    }
  );

  return mutate;
}
