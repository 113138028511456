import { Toggle } from "@patientmpower/spiro";
import * as Popover from "@radix-ui/react-popover";
import { useEffect, useRef, useState } from "react";

import { HorizontalDots } from "../../../../../../assets/icons/HorizontalDots";
import { CustomIconButton } from "../../../../../../components/CustomIconButton";
import { ModalOptions } from "./Options.types";
import {
  OptionItem,
  OptionsContainer,
  StyledPopoverContent,
  StyledPopoverTrigger,
} from "./OptionsPopover.styles";

type CustomPopoverProps = React.ComponentProps<typeof Popover.Root> & {
  children: React.ReactNode;
};

type OptionsPopoverProps = {
  initialOptions?: ModalOptions;
  onChangeOption: (options: ModalOptions) => void;
};

function CustomPopover({ children }: CustomPopoverProps) {
  return (
    <Popover.Root>
      <StyledPopoverTrigger>
        <CustomIconButton>
          <HorizontalDots />
        </CustomIconButton>
      </StyledPopoverTrigger>

      <Popover.Portal>
        <StyledPopoverContent>{children}</StyledPopoverContent>
      </Popover.Portal>
    </Popover.Root>
  );
}

export function OptionsPopover({
  initialOptions,
  onChangeOption,
}: OptionsPopoverProps) {
  const isFirstRender = useRef(true);

  const [options, setOptions] = useState({
    scaleYAxis: initialOptions?.scaleYAxis || false,
  });

  const handleOnClickToScaleYAxis = () => {
    setOptions((prevState) => ({
      ...prevState,
      scaleYAxis: !prevState.scaleYAxis,
    }));
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }

    onChangeOption(options);
  }, [options]);

  return (
    <CustomPopover>
      <OptionsContainer>
        <OptionItem>
          <p>Scale y axis</p>

          <Toggle
            checked={options?.scaleYAxis}
            onCheckedChange={handleOnClickToScaleYAxis}
          />
        </OptionItem>
      </OptionsContainer>
    </CustomPopover>
  );
}
