import { Tile, styled, css } from "@patientmpower/spiro";

// Components
export const Container = styled("div", {
  height: "100%",

  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",

  backgroundColor: "$gray-20",

  "@sm": {
    width: "100%",
  },
});

export const LoadingOverlay = styled("div", {
  width: "542px",
  height: "502px",
  borderRadius: "$4",

  zIndex: 200,
  opacity: 0.64,
  position: "absolute",

  backgroundColor: "$white",

  "@sm": {
    width: "100%",
    height: "100%",
  },
});

export const StyledTile = styled(Tile, {
  width: "547px",
  height: "520px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  backgroundColor: "$white",

  "@md": {
    width: "448px",
    height: "490px",
  },

  "@sm": {
    width: "100%",
    height: "100%",

    padding: "0px 42px",

    justifyContent: "flex-start",
    flexDirection: "column",
  },
});

export const Logo = styled("img", {
  width: "228px",
  height: "54px",

  marginBottom: "52px",

  "@sm": {
    margin: "$13 0 $14 0",

    width: "169px",
    height: "$13",
  },
});

export const ContentContainer = styled("form", {
  width: "410px",

  "@sm": {
    width: "100%",
  },
});

export const ErrorMessage = styled("p", {
  fontFamily: "$openSans",
  fontWeight: "600",
  fontSize: "$text-md",

  margin: "38px 0 34px 0",
  color: "$error-light",
});

export const FooterLinksContainerDesktop = styled("div", {
  display: "flex",

  gap: "18px",
  marginTop: "$5",

  "@sm": {
    display: "none",
  },
});

export const FooterLinksContainerMobile = styled("div", {
  display: "none",

  "@sm": {
    width: "100%",

    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    gap: "$5",

    marginTop: "88px",
    paddingTop: "$8",

    borderTop: "1px solid $gray-50",
  },
});

// CSS Styles
export const emailTextFieldStyles = css({
  marginBottom: "$12",

  "@sm": {
    marginBottom: "28px",
  },
});

export const passwordTextFieldStyles = css({
  marginBottom: "$13",

  "@sm": {
    marginBottom: "$12",
  },
});

export const loginButtonStyles = css({
  zIndex: 300,
  position: "relative",
});
