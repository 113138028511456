import { IRoutePattern } from "../@types/Route";
import { Lab } from "../assets/icons/Lab";
import { Panel } from "../assets/icons/Panel";
import { Vitals } from "../assets/icons/Vitals";

export const mainRoutes: IRoutePattern[] = [
  {
    name: "Patients",
    path: "/patients",
    disabled: false,
    Icon: Panel,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    disabled: true,
    Icon: Vitals,
  },
  {
    name: "Admin",
    path: "/admin",
    disabled: true,
    Icon: Lab,
  },
];
