import { useQuery } from "@tanstack/react-query";

import { spirometryService } from "../../services/spirometryService";

export function usePatientSpirometryMeasurements(
  patientId: string | undefined
) {
  const { data, isError, isFetching } = useQuery(
    [`${patientId}-spirometry-measurements`],
    async () => {
      const { data } =
        await spirometryService.getPatientSpirometryMeasurementsValuesById(
          patientId
        );

      // TODO: parse data and return with a pattern for the component, so we don't need to change in a lot of places
      return data;
    },
    { refetchOnMount: "always", enabled: !!patientId }
  );

  return { spirometryMeasurements: data, isFetching, isError };
}

export function useSpirometryVolumeFlow(spirometryIds?: number[]) {
  const { data, isError, isFetching } = useQuery(
    [`spirometry-volume-flow`, spirometryIds],
    async () => {
      if (!spirometryIds) return [];

      const spirometryVolumeFlowValuesPromises = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < spirometryIds.length; i++) {
        const spirometryId = spirometryIds[i];

        spirometryVolumeFlowValuesPromises.push(
          spirometryService.getSpirometryVolumeFlow(spirometryId)
        );
      }

      const spirometryVolumeFlowValuesResponses = await Promise.all(
        spirometryVolumeFlowValuesPromises
      );

      return spirometryVolumeFlowValuesResponses.map(
        (spirometryVolumeFlowValues) => spirometryVolumeFlowValues.data
      )[0];
    },
    { refetchOnMount: "always", enabled: !!spirometryIds }
  );

  return { volumeFlowValues: data, isError, isFetching };
}

export function useSpirometryTimeVolume(spirometryIds?: number[]) {
  const { data, isError, isFetching } = useQuery(
    [`spirometry-time-volume`, spirometryIds],
    async () => {
      if (!spirometryIds) return [];

      const spirometryTimeVolumeValuesPromises = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < spirometryIds.length; i++) {
        const spirometryId = spirometryIds[i];

        spirometryTimeVolumeValuesPromises.push(
          spirometryService.getSpirometryTimeVolume(spirometryId)
        );
      }

      const spirometryTimeVolumeValuesResponses = await Promise.all(
        spirometryTimeVolumeValuesPromises
      );

      return spirometryTimeVolumeValuesResponses.map(
        (spirometryTimeVolumeValues) => spirometryTimeVolumeValues.data
      )[0];
    },
    { refetchOnMount: "always", enabled: !!spirometryIds }
  );

  return { timeVolumeValues: data, isError, isFetching };
}

export function useArtiQSpirometryData(spirometryId: number[]) {
  const { data, isError, isFetching } = useQuery(
    ["spirometry-artiq-data", spirometryId],
    async () => {
      const { data } = await spirometryService.getArtiqSpitormetryData(
        spirometryId[0]
      );

      // const lastTrialSpirometryResult =
      //   data?.spirometry_test_assessment_trial_result &&
      //   data?.spirometry_test_assessment_trial_result.length &&
      //   data?.spirometry_test_assessment_trial_result[0];

      // const lastSpirometryResultSession =
      //   data?.spirometry_test_assessment_session_result &&
      //   data?.spirometry_test_assessment_session_result.length &&
      //   data?.spirometry_test_assessment_session_result[0];

      const fvcAcceptabilityReasonParsed =
        data?.trial_result?.fvc_acceptability_reason &&
        data?.trial_result?.fvc_acceptability_reason.split(";");

      const parsedArtiQSpirometryData = {
        fvcAcceptabilityReason: fvcAcceptabilityReasonParsed,
        fev1Acceptability: data?.trial_result?.fev1_acceptability,
        fvcAcceptability: data?.trial_result?.fvc_acceptability,

        fev1Grade: undefined,
        fvcGrade: undefined,

        bestValues: {
          fev1: "-",
          fvc: "-",
          fev1fvc: "-",
          pef: "-",
        },
      };

      return parsedArtiQSpirometryData;
    },
    { refetchOnMount: "always", cacheTime: 0, enabled: !!spirometryId }
  );

  return { artiQData: data, isError, isFetching };
}
