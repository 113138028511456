import { useEffect, useState } from "react";

const getIsMobile = () => window.innerWidth <= 768;

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [widthView, setWidthView] = useState(window.innerWidth);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(getIsMobile());
      setWidthView(window.innerWidth);
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return { isMobile, widthView };
}
