import {
  Line,
  XAxis,
  Legend,
  Tooltip,
  LineChart,
  ResponsiveContainer,
} from "recharts";

import useIsMobile from "../../hooks/useIsMobile";
import {
  Label,
  Value,
  Circle,
  ChartTitle,
  StyledTile,
  StyledLegend,
  ChartValueContainer,
  StyledTooltip,
  StyledSubtitle,
  EmptyStateOverlay,
} from "./ChartCard.styles";
import {
  LegendContent,
  ChartCardValue,
  ChartCardValuesProps,
  RenderCustomLegendProps,
  ChartData,
  ChartConfig,
} from "./ChartCard.types";
import { ChartCardLoading } from "./ChartCardLoading";

export type ChartCardProps = {
  title: string;
  data: ChartData[];
  onClick: React.MouseEventHandler<HTMLDivElement>;
  isLoading?: boolean;
  chartConfig: ChartConfig[];
  specificValues: ChartCardValue[];
};

export function ChartSpecificValues({ values }: ChartCardValuesProps) {
  return (
    <ChartValueContainer>
      {values.map((currentValue: ChartCardValue, index) => (
        <div key={`${currentValue.label}_${currentValue.value}_${index}`}>
          <Value>{currentValue.value}</Value>
          <Label>{currentValue.label}</Label>
        </div>
      ))}
    </ChartValueContainer>
  );
}

export function CustomTooltip({ label, active, payload }: any) {
  if (active && payload && payload.length) {
    if (payload.length === 1) {
      return (
        <StyledTooltip singleValue>
          <h4>{payload[0].value}</h4>
          <p>{label}</p>
        </StyledTooltip>
      );
    }

    return (
      <StyledTooltip>
        {payload.map((currentPayload: any, index: number) => {
          const { name } = currentPayload;

          const nameFiltered =
            name && (name as string).indexOf("-")
              ? (name as string).split("-")[0].trim()
              : name;

          return (
            <h4
              key={`${currentPayload.value}_${name}_${index}`}
            >{`${nameFiltered}: ${currentPayload.value}`}</h4>
          );
        })}

        <p>{label}</p>
      </StyledTooltip>
    );
  }

  return null;
}

export function ChartCard({
  data,
  title,
  onClick,
  isLoading,
  chartConfig,
  specificValues,
}: ChartCardProps) {
  const { isMobile } = useIsMobile();
  const isEmptyState = !data || !data.length;

  const isModalChartEnabled = !isEmptyState && !isMobile;

  function renderCustomLegend({ legends, disabled }: RenderCustomLegendProps) {
    if (disabled) return [];

    return legends.map((legendContent: LegendContent, index) => (
      <StyledLegend key={`${legendContent.dataKey}_${index}`}>
        <Circle css={{ backgroundColor: legendContent.color }} />

        {legendContent.value}
      </StyledLegend>
    ));
  }

  return (
    <StyledTile
      disabled={isEmptyState}
      onClick={isModalChartEnabled ? onClick : undefined}
    >
      {isLoading ? (
        <ChartCardLoading />
      ) : (
        <>
          <ChartTitle>{title}</ChartTitle>

          {isEmptyState && (
            <StyledSubtitle>
              {title === "-" ? "No data to show" : "No data for selected dates"}
            </StyledSubtitle>
          )}
          {isEmptyState && <EmptyStateOverlay />}

          <ResponsiveContainer
            width="100%"
            height={isEmptyState ? "57%" : "67%"}
          >
            <LineChart data={data}>
              {!isEmptyState && (
                <Legend
                  height={80}
                  verticalAlign="top"
                  content={({ payload }) =>
                    renderCustomLegend({
                      legends: payload as unknown as LegendContent[],
                      disabled: true,
                    })
                  }
                />
              )}

              <Tooltip
                cursor={false}
                content={<CustomTooltip />}
                wrapperStyle={{ outline: "none" }}
              />
              <XAxis hide dataKey="time" />

              {chartConfig.map((currentChartConfig, index) => (
                <Line
                  key={`${currentChartConfig.yKey}_${index}_${currentChartConfig.color}`}
                  connectNulls
                  isAnimationActive={false}
                  type={currentChartConfig?.lineType || "monotone"}
                  strokeWidth={currentChartConfig.stroke || 1.4}
                  dot={
                    currentChartConfig.dot && {
                      strokeWidth: currentChartConfig.stroke || 1.4,
                    }
                  }
                  dataKey={currentChartConfig.yKey}
                  stroke={currentChartConfig.color}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>

          <ChartSpecificValues values={specificValues} />
        </>
      )}
    </StyledTile>
  );
}
