import { styled } from "@patientmpower/spiro";

export const Container = styled("div", {
  display: "flex",

  height: "100%",
});

export const MeasurementContainer = styled("div", {
  height: "100%",
  width: "100%",

  padding: "24px 24px 24px 0",
});

export const Title = styled("h1", {
  fontWeight: 500,
  fontSize: "$text-xl",
  lineHeight: "22px",

  color: "$black",
});

export const Subtitle = styled("h2", {
  marginTop: "4px",
  marginBottom: "12px",

  color: "$gray-80",

  fontWeight: 400,
  fontSize: "12px",

  lineHeight: "16px",
});

export const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",

  marginLeft: "28px",
  marginBottom: "28px",

  "&:last-child": {
    marginBottom: "28px",
  },
});

export const SpirometryListTitle = styled("p", {
  lineHeight: "16px",
  textAlign: "end",

  marginRight: "8px",
  marginBottom: "8px",

  fontWeight: 600,
  fontSize: "$text-md",

  color: "$gray-90",
});
