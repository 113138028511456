import { css } from "@patientmpower/spiro";
import { useRoutes } from "react-router-dom";

import { Modal } from "./components/Modal";
import { useModal } from "./hooks/useModal";
import { globalStyles } from "./layout/styles/global";
import { routes } from "./routes/routes";

const containerStyles = css({
  width: "100%",
  height: "100vh",
  fontFamily: "$openSans",
});

// TODO: try to remove all the any values.
// TODO: Improve all the types from the app
// TODO: Add history and redirects
function App() {
  const routeElements = useRoutes(routes);
  const { isModalOpen, modalContent, closeModal, optionsModal } = useModal();

  globalStyles();
  return (
    <div className={containerStyles()}>
      <Modal
        open={isModalOpen}
        optionsModal={optionsModal}
        onOpenChange={() => {
          if (isModalOpen) {
            closeModal();
          }
        }}
      >
        {modalContent}
      </Modal>

      {routeElements}
    </div>
  );
}

export default App;
