import {
  DatePickerInput,
  OptionButton,
  OptionsBar,
} from "@patientmpower/spiro";
import dayjs from "dayjs";
import { useState } from "react";

import { Calendar } from "../../../../../../assets/icons/Calendar";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import { DatepickerContainer } from "./CustomOptionsBar.styles";
import { dateFilterOptions } from "./dateFilterOptions";

type CustomOptionsBarType = {
  cardTitle: string;
  onChange: (selectedDateFilter: DateFilterOptionType) => void;
  selectedFilterDate: DateFilterOptionType;
};

export type DateFilterOptionType = {
  label: string;
  endDate: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
};

export function CustomOptionsBar({
  cardTitle,
  onChange,
  selectedFilterDate,
}: CustomOptionsBarType) {
  const [calendarOpen, setCalendarOpen] = useState(false);

  const cardTitleId = cardTitle.replaceAll(" ", "_");
  const customDateFiterOption = "custom";

  const handleOnChangeDateWithDatePicker = (dateRange: any) => {
    if (dateRange.startDate && dateRange.endDate) {
      setCalendarOpen((prevState) => !prevState);
    } else {
      return;
    }

    onChange({
      label: customDateFiterOption,
      startDate: dayjs(dateRange.startDate),
      endDate: dayjs(dateRange.endDate),
    });
  };

  return (
    <>
      <OptionsBar>
        <OptionButton
          key={`${cardTitleId}_customDate`}
          text="Custom"
          icon={<Calendar />}
          selected={selectedFilterDate.label === customDateFiterOption}
          onClick={() => {
            mixpanelActions.track(`UserAction: CustomDateFilter`);
            setCalendarOpen((prevState) => !prevState);
          }}
        />

        {dateFilterOptions.map((dateFilterOption, index) => {
          return (
            <OptionButton
              key={`${cardTitleId}_${dateFilterOption.label}_${index}`}
              text={dateFilterOption.label}
              selected={dateFilterOption.label === selectedFilterDate.label}
              onClick={() => {
                mixpanelActions.track(
                  `UserAction: Changed Date Range ${dateFilterOption.label}`
                );

                onChange({
                  endDate: dayjs(),
                  label: dateFilterOption.label,
                  startDate: dateFilterOption.value,
                });
              }}
            />
          );
        })}
      </OptionsBar>

      {calendarOpen && (
        <DatepickerContainer>
          <DatePickerInput
            initialDateRange={{
              startDate: selectedFilterDate.startDate.toDate(),
              endDate: selectedFilterDate.endDate.toDate(),
            }}
            onDateChange={handleOnChangeDateWithDatePicker}
          />
        </DatepickerContainer>
      )}
    </>
  );
}
