import { styled } from "@patientmpower/spiro";

export const TableContainer = styled("div", {
  width: "100%",
  display: "flex",

  justifyContent: "flex-end",
});

export const StyledTable = styled("table", {
  width: "198px",

  th: {
    lineHeight: "16px",

    fontWeight: 600,
    fontSize: "$text-md",

    color: "$gray-100",

    "&:nth-child(2)": {
      minWidth: "26px",
      paddingRight: "26px",
    },

    "&:last-child": {
      textAlign: "end",
      paddingRight: "7px",

      width: "50px",
    },
  },

  thead: {
    marginBottom: "8px",
  },

  td: {
    fontFamily: "$openSans",
    fontSize: "$text-md",

    marginBottom: "4px",

    color: "$gray-90",
    textAlign: "center",

    "&:first-child": {
      textAlign: "start",
    },

    "&:nth-child(2)": {
      minWidth: "26px",
      paddingRight: "26px",
    },

    "&:last-child": {
      textAlign: "start",
      paddingLeft: "2px",
    },
  },
});
