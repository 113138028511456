import { QueryClient } from "@tanstack/react-query";

const defaultOptions = {
  queries: {
    retry: 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 180000,
  },
};

const queryClient = new QueryClient({ defaultOptions });
export { queryClient };
