import { Table } from "@patientmpower/spiro";
import { useNavigate } from "react-router-dom";

import { IPatient, PatientListTableContent } from "../../../../@types/Patient";
import { TableSkeleton } from "../../../../components/TableSkeleton";
import { patientListVariants } from "../../../../constants/patientListVariants";
import useIsMobile from "../../../../hooks/useIsMobile";
import { MobileList } from "./components/MobileList";
import { desktopTableColumns } from "./tableColumns";

type PatientListTableProps = {
  content: PatientListTableContent[];
  isLoading?: boolean;
  variant: string;
};

export function PatientListTable({
  content,
  variant,
  isLoading,
}: PatientListTableProps) {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const handleRowClick = (patientContent: IPatient) => {
    navigate(`/patients/${patientContent.id}/spirometry`);
  };

  const emptyStateText =
    variant === patientListVariants.ALERTS
      ? "No patients with alerts"
      : `No patients ${variant}`;

  if (isLoading) {
    return <TableSkeleton columnsNumber={8} />;
  }

  if (isMobile) {
    return (
      <MobileList
        onRowClick={handleRowClick}
        emptyStateText={emptyStateText}
        data={content}
      />
    );
  }

  return (
    <Table
      data={content}
      onRowClick={handleRowClick}
      emptyStateText={emptyStateText}
      columns={desktopTableColumns}
    />
  );
}

PatientListTable.defaultProps = {
  isLoading: false,
};
