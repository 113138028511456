import { createColumn } from "@patientmpower/spiro";

export const desktopTableColumns = [
  createColumn({
    keyName: "type",
    header: () => "Type",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "trigger",
    header: () => "Trigger",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "timeFrame",
    header: () => "Time frame",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "time",
    header: () => "Time of alert",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "alertStatus",
    header: () => "Alert status",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "resolvedBy",
    header: () => "Resolved by",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "action",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 30,
  }),
];
