import * as DialogPrimitive from "@radix-ui/react-dialog";
import React from "react";

import { OptionsModalType } from "../../@types/Modal";
import { Close } from "../../assets/icons/Close";
import { CustomIconButton } from "../CustomIconButton";
import {
  StyledDialogClose,
  StyledDialogContent,
  StyledDialogOverlay,
  StyledTile,
} from "./Modal.styles";

type ModalProps = React.ComponentProps<typeof DialogPrimitive.Root> &
  DialogPrimitive.DialogContentProps & {
    optionsModal: OptionsModalType;
  };

export function Modal({ children, optionsModal, ...props }: ModalProps) {
  return (
    <DialogPrimitive.Root {...props}>
      <StyledDialogOverlay>
        <StyledDialogContent>
          <StyledTile css={{ width: optionsModal.width || "925px" }}>
            <StyledDialogClose>
              <CustomIconButton>
                <Close />
              </CustomIconButton>
            </StyledDialogClose>

            {children}
          </StyledTile>
        </StyledDialogContent>
      </StyledDialogOverlay>
    </DialogPrimitive.Root>
  );
}
