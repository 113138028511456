import { IRoutePattern } from "../@types/Route";
import { Alert } from "../assets/icons/Alert";
import { Book } from "../assets/icons/Book";
import { DoubleFlag } from "../assets/icons/DoubleFlag";
import { File } from "../assets/icons/File";
import { Lab } from "../assets/icons/Lab";
import { Panel } from "../assets/icons/Panel";
import { Pencil } from "../assets/icons/Pencil";
import { People } from "../assets/icons/People";
import { Pill } from "../assets/icons/Pill";
import { Spirometry } from "../assets/icons/Spirometry";
import { Vitals } from "../assets/icons/Vitals";

export const patientListChildRoutes: IRoutePattern[] = [
  {
    name: "Active",
    path: "/patients/active",
    disabled: false,
    Icon: People,
    context: "Patient List",
  },
  {
    name: "Alerts",
    path: "/patients/alerts",
    disabled: false,
    Icon: Alert,
    context: "Patient List",
  },
  {
    name: "Flagged",
    path: "/patients/flagged",
    disabled: false,
    Icon: DoubleFlag,
    context: "Patient List",
  },
  {
    name: "Archived",
    path: "/patients/archived",
    disabled: false,
    Icon: File,
    context: "Patient List",
  },
];

export const patientPageChildRoutes: IRoutePattern[] = [
  {
    name: "Overview",
    path: "/patients/:patientId/overview",
    disabled: true,
    Icon: Panel,
    context: "Patient Page",
  },
  {
    name: "Spirometry",
    path: "/patients/:patientId/spirometry",
    disabled: false,
    Icon: Spirometry,
    context: "Patient Page",
  },
  {
    name: "Measurements",
    path: "/patients/:patientId/measurements",
    disabled: false,
    Icon: Vitals,
    context: "Patient Page",
  },
  {
    name: "Labs",
    path: "/patients/:patientId/labs",
    disabled: false,
    Icon: Lab,
    context: "Patient Page",
  },
  {
    name: "Notes",
    path: "/patients/:patientId/notes",
    disabled: true,
    Icon: Pencil,
    context: "Patient Page",
  },
  {
    name: "Medication",
    path: "/patients/:patientId/medication",
    disabled: true,
    Icon: Pill,
    context: "Patient Page",
  },
  {
    name: "Surveys & Journal",
    path: "/patients/:patientId/surveys-journal",
    disabled: true,
    Icon: Book,
    context: "Patient Page",
  },
];
