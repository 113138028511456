export function Book({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5833 4.75C16.5583 4.75 17.575 4.875 18.5 5.16667C19.125 5.375 19.6666 5.625 20.1666 6V18.1667C20.1666 18.375 19.9583 18.5833 19.75 18.5833C19.6666 18.5833 19.625 18.5833 19.5416 18.5417C18.375 17.9167 16.9583 17.6667 15.5833 17.6667C14.1666 17.6667 12.125 18.2083 11 18.9167C9.79165 18 8.04165 17.6667 6.41665 17.6667C5.20831 17.6667 3.58331 18.0417 2.45831 18.5833C2.41665 18.5833 2.3854 18.5937 2.35415 18.6042C2.3229 18.6146 2.29165 18.625 2.24998 18.625C2.04165 18.625 1.83331 18.4167 1.83331 18.2083V6C3.04165 5.08333 4.79165 4.75 6.41665 4.75C8.04165 4.75 9.79165 5.08333 11 6C12.2083 5.08333 13.9583 4.75 15.5833 4.75ZM15.5833 16C16.5833 16 17.5833 16.125 18.5 16.4167V6.83333C17.5833 6.54167 16.5833 6.41667 15.5833 6.41667C14.1666 6.41667 12.125 6.95833 11 7.66667V17.25C12.125 16.5417 14.1666 16 15.5833 16Z"
      />
      <path d="M15.5833 9.75C16.3166 9.75 17.025 9.825 17.6666 9.96667V8.7C17.0083 8.575 16.3 8.5 15.5833 8.5C14.1666 8.5 12.8833 8.74167 11.8333 9.19167V10.575C12.775 10.0417 14.0833 9.75 15.5833 9.75Z" />
      <path d="M11.8333 11.4083V12.7917C12.775 12.2583 14.0833 11.9667 15.5833 11.9667C16.3166 11.9667 17.025 12.0417 17.6666 12.1833V10.9167C17.0083 10.7917 16.3 10.7167 15.5833 10.7167C14.1666 10.7167 12.8833 10.9667 11.8333 11.4083Z" />
      <path d="M15.5833 12.9417C14.1666 12.9417 12.8833 13.1833 11.8333 13.6333V15.0167C12.775 14.4833 14.0833 14.1917 15.5833 14.1917C16.3166 14.1917 17.025 14.2667 17.6666 14.4083V13.1417C17.0083 13.0083 16.3 12.9417 15.5833 12.9417Z" />
    </svg>
  );
}
