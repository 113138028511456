import { styled, IconButton } from "@patientmpower/spiro";

export const closedPatientLateralMenuCardContainerWidth = 32;
export const expandedPatientLateralMenuCardContainerWidth = 262;

export const PatientLateralMenu = styled("div", {
  zIndex: 800,

  right: 0,

  display: "flex",
  position: "fixed",

  backgroundColor: "$white",

  borderLeft: "1px solid $gray-40",

  width: closedPatientLateralMenuCardContainerWidth,
  height: "calc(100vh - 62px)",

  transition: "width .4s",

  variants: {
    isLateralPatientMenuOpenOnDesktop: {
      true: {
        zIndex: 1000,

        width: expandedPatientLateralMenuCardContainerWidth,
      },
    },
  },

  "@sm": {
    display: "none",
  },
});

export const PatientLateralMenuCardContainer = styled("div", {
  position: "fixed",

  width: expandedPatientLateralMenuCardContainerWidth,
  height: "calc(100vh - 62px)",

  overflowY: "auto",
  overflowX: "hidden",

  transition: "opacity .150ms",

  variants: {
    isLateralPatientMenuOpenOnDesktop: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },
});

export const ExpandButton = styled(IconButton, {
  alignSelf: "flex-end",

  marginBottom: 68,
  marginLeft: -15,

  transition: "transform .4s",

  transform: "rotate(180deg)",

  div: {
    paddingLeft: 10,
  },

  "&:focus-visible": {
    outline: "none !important",
    border: "2px solid $primary-30",
  },

  "&:focus": {
    outline: "none !important",
  },

  variants: {
    isExpanded: {
      true: {
        transform: "rotate(360deg)",
      },
    },
  },
});

export const EditButtonContainer = styled("div", {
  zIndex: "$1",
  position: "fixed",

  maxHeight: 52,
  width: expandedPatientLateralMenuCardContainerWidth,

  overflow: "hidden",

  display: "flex",
  justifyContent: "flex-end",

  padding: 12,
});

export const EditButton = styled(IconButton, {
  backgroundColor: "$gray-60",

  cursor: "pointer",
});

export const EditControlsContainer = styled("div", {
  backgroundColor: "$black",

  width: 168,
  height: 48,
  borderRadius: 10,

  position: "absolute",
  bottom: 22,
  left: "50%",
  translate: "-50%",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  columnGap: 19,
});

export const EditControlsButton = styled("button", {
  cursor: "pointer",

  fontFamily: "$openSans",

  fontSize: "$text-md",

  fontWeight: 600,

  border: "none",
  borderRadius: "$4",

  variants: {
    primary: {
      true: {
        width: 60,
        height: 26,

        backgroundColor: "$white",
      },
      undefined: {
        color: "$white",

        backgroundColor: "transparent",
      },
    },
  },
});
