import { styled } from "@patientmpower/spiro";

import { expandedPatientLateralMenuCardContainerWidth } from "../PatientLateralMenu/PatientLateralMenu.styles";

export const DesktopContainer = styled("div", {
  zIndex: 900,
  boxSizing: "border-box",

  height: 62,
  width: `calc(100% - 88px)`,

  backgroundColor: "$white",
  borderBottom: "1px solid $gray-30",
  transition: "width .4s",

  position: "fixed",

  top: 62,
  left: 88,

  paddingRight: "50px",
  paddingLeft: "36px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  variants: {
    isLateralPatientMenuOpenOnDesktop: {
      true: {
        transition: "width .4s",

        width: `calc(100% - 88px - ${expandedPatientLateralMenuCardContainerWidth}px)`,
      },
    },
  },

  "@sm": {
    display: "none",
  },
});

export const Container = styled("div", {
  display: "flex",

  alignItems: "center",
  justifyContent: "space-between",

  width: "1519px",

  "@md": {
    width: "1220px",
  },

  "@sm": {
    width: "100%",
    margin: 0,
  },
});

export const Title = styled("p", {
  fontFamily: "$nunito",

  fontWeight: 700,
  fontSize: "$text-xxl",

  color: "$gray-100",

  "@sm": {
    fontSize: "$text-lg",
    lineHeight: "19px",
  },
});

export const MobileTitleContainer = styled("div", {
  display: "flex",
  flexDirection: "flex-start",

  padding: "16px 16px 22px 16px",

  span: {
    margin: "0 4px",
  },
});

export const TitleContainer = styled("div", {
  display: "flex",
  alignItems: "center",

  span: {
    fontSize: "18px",
    margin: "0 8px",

    fontFamily: "$openSans",
    color: "$gray-100",
  },
});

export const Text = styled("p", {
  fontFamily: "$openSans",

  fontWeight: 600,
  lineHeight: "16px",
  fontSize: "$text-md",

  color: "$gray-100",
});

export const AlertsContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  flexGrow: 1,

  gap: 8,
});

export const PatientCard = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr 1.5fr",
  alignItems: "center",

  fontFamily: "$openSans",
  fontSize: "$text-md",
  color: "$gray-90",

  paddingLeft: 38,
  paddingRight: 24,

  height: 58,

  borderBottom: "1px solid $gray-50",

  p: {
    "&:first-child": {
      fontWeight: 600,
    },

    "&:last-child": {
      fontWeight: 400,
    },
  },
});
