export function getLongFormattedDate(date: string) {
  const dateObj = new Date(date);

  const month = dateObj.toLocaleDateString("default", { month: "short" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  const time = dateObj.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const formattedTime = time.toLocaleLowerCase().replace(/ /g, "");

  return `${formattedTime}, ${month} ${day}, ${year}`;
}

export function getShortFormattedDate(date: string) {
  const dateObj = new Date(date);

  const month = dateObj.toLocaleDateString("default", { month: "short" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  return `${month} ${day}, ${year}`;
}
