import { Navigate, RouteObject } from "react-router-dom";

import { DashboardLayout } from "../layout/components/DashboardLayout";
import { LoginPage } from "../pages/Login";
import { NotFound } from "../pages/NotFound";
import { PatientList } from "../pages/PatientList";
import { PatientPage } from "../pages/PatientPage";
import { patientListChildRoutes, patientPageChildRoutes } from "./child.routes";

export const routes: RouteObject[] = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/patients" />,
      },
      {
        path: "/patients",
        children: [
          { index: true, element: <Navigate to="active" /> },
          {
            path: "active",
            element: <PatientList variant="active" />,
          },
          {
            path: "archived",
            element: <PatientList variant="archived" />,
          },
          {
            path: "flagged",
            element: <PatientList variant="flagged" />,
          },
          {
            path: "alerts",
            element: <PatientList variant="alerts" />,
          },
          {
            path: ":patientId",
            children: [
              {
                index: true,
                element: <Navigate to="overview" />,
              },
              {
                path: "overview",
                element: <PatientPage />,
              },
              {
                path: "spirometry",
                element: <PatientPage />,
              },
              {
                path: "measurements",
                element: <PatientPage />,
              },
              {
                path: "labs",
                element: <PatientPage />,
              },
              {
                path: "notes",
                element: <PatientPage />,
              },
              {
                path: "medication",
                element: <PatientPage />,
              },
              {
                path: "surveys-journal",
                element: <PatientPage />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export const routesWithChildRoutes = [
  {
    paths: [
      "/patients/active",
      "/patients/alerts",
      "/patients/flagged",
      "/patients/archived",
    ],
    childRoutes: patientListChildRoutes,
  },
  {
    paths: [
      "/patients/:patientId/overview",
      "/patients/:patientId/measurements",
      "/patients/:patientId/spirometry",
      "/patients/:patientId/labs",
      "/patients/:patientId/notes",
      "/patients/:patientId/medication",
      "/patients/:patientId/surveys-journal",
    ],
    childRoutes: patientPageChildRoutes,
  },
];
