import {
  AccordionList,
  AccordionListItem,
  AccordionListItemDefaultContent,
} from "@patientmpower/spiro";

import { IPatient } from "../../../../../../@types/Patient";
import { AlertsCounter } from "../../../../../../components/AlertsCounter";
import { useFlagPatient } from "../../../../../../hooks/mutations/patients";
import { mobileAccordionListColumns } from "../../tableColumns";
import { FlagPatientButton } from "../FlagPatientButton";
import { AccordionTitleButton, EmptyStateContainer } from "./MobileList.style";

type IMobileListProps = {
  data: IPatient[];
  emptyStateText?: string;
  onRowClick: (rowContent: any) => void;
};

export function MobileList({
  data,
  onRowClick,
  emptyStateText,
}: IMobileListProps) {
  const haveData = Boolean(data && data.length);
  const mutate = useFlagPatient();

  if (!haveData) {
    return (
      <EmptyStateContainer>
        <p>{emptyStateText || "No data to show"}</p>
      </EmptyStateContainer>
    );
  }

  // TODO: Virtualize this accordion list in the future
  return (
    <AccordionList type="single" collapsible>
      {data.map((patient, index) => {
        return (
          <AccordionListItem
            key={`${patient.id}_${index}`}
            index={index + 2} // Adding +2 to start the list with gray color
            title={
              <AccordionTitleButton
                onClick={(event) => {
                  event.stopPropagation();

                  onRowClick(patient);
                }}
              >
                {patient.name}
              </AccordionTitleButton>
            }
            value={`patient-list-${index}`}
            subtitle={`Last used: ${patient.lastUsed}`}
            headerComponents={
              <div style={{ display: "flex", alignItems: "center" }}>
                {patient.alerts !== "-" && (
                  <AlertsCounter>{patient.alerts}</AlertsCounter>
                )}

                <FlagPatientButton
                  onClick={(e) => {
                    e.stopPropagation();
                    mutate({
                      patientId: patient.id,
                      flagged: !patient.flagged,
                    });
                  }}
                  isMobile
                  flagged={patient.flagged}
                />
              </div>
            }
          >
            <AccordionListItemDefaultContent
              content={patient}
              columns={mobileAccordionListColumns}
            />
          </AccordionListItem>
        );
      })}
    </AccordionList>
  );
}
