import { IAuthAPIResponse, ILogin, ILoginResponse } from "../@types/Login";
import { api } from "./api";

function errorHandler({ errors }: { errors: any }) {
  const loginFieldError = {
    error: "non_field_errors",
    message: "Unable to log in with provided credentials.",
  };

  const loginFieldErrorFound = errors ? errors[loginFieldError.error] : false;
  if (loginFieldErrorFound)
    if (
      loginFieldErrorFound.find(
        (currentError: string) => currentError === loginFieldError.message
      )
    ) {
      return "Please check your credentials and try again.";
    }

  return "Sorry, something went wrong. Please try again.";
}

export const authService = {
  async authenticate({ email, password }: ILogin): Promise<ILoginResponse> {
    try {
      const responseFromAPI = await api.post<IAuthAPIResponse>(
        "/api/auth/token-auth/",
        {
          username: email,
          password,
        }
      );

      const { data } = responseFromAPI;
      return { token: data.token } as ILoginResponse;
    } catch (error: any) {
      const { data: errors } = error.response || {};

      return {
        errors: errorHandler({ errors }),
      } as ILoginResponse;
    }
  },
};
