import { ReactNode } from "react";

import { Container, PageContainer } from "./PageLayout.styles";

type IPageLayoutProps = {
  children: ReactNode;
  className?: string;
};

export function PageLayout({ children, className }: IPageLayoutProps) {
  return (
    <Container className={className}>
      <PageContainer>{children}</PageContainer>
    </Container>
  );
}

PageLayout.defaultProps = {
  className: "",
};
