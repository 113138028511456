import { MeasurementTypes } from "../../../../@types/Measurements";
import { IPatientInfo } from "../../../../@types/Patient";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useMeasurementsByCondition } from "../../../../hooks/useMeasurementsByCondition";
import { PageTitle } from "../../../../layout/components/PageTitle";
import {
  ChartsContainer,
  MobilePatientPageTitleContainer,
} from "../../PatientPage.styles";
import { MeasurementCard } from "../MeasurementCard";

type MeasurementSectionProps = {
  patient: IPatientInfo;
};

export function MeasurementSection({ patient }: MeasurementSectionProps) {
  const { isMobile } = useIsMobile();

  const { measurements } = useMeasurementsByCondition({
    condition: patient?.condition,
  });

  return (
    <>
      {!isMobile && <PageTitle>Measurements</PageTitle>}

      <ChartsContainer>
        {isMobile && (
          <MobilePatientPageTitleContainer>
            <PageTitle>Measurements</PageTitle>
          </MobilePatientPageTitleContainer>
        )}

        {measurements.map((measurement, index) => {
          return (
            <MeasurementCard
              key={`${measurement.title}_${measurement.type}_${index}`}
              cardTitle={`${measurement.title} ${
                measurement.unit ? `(${measurement.unit})` : ""
              }`}
              measurementType={measurement.type as MeasurementTypes}
              chartConfig={[
                {
                  dot: false,
                  lineType: "monotone",
                  yKey: measurement.type,
                  color: measurement.color,
                },
              ]}
            />
          );
        })}
      </ChartsContainer>
    </>
  );
}
