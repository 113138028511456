export function Alert({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 14.6667V10.0834C16.5 7.26925 15.0058 4.91341 12.375 4.29008V3.66675C12.375 2.90591 11.7608 2.29175 11 2.29175C10.2392 2.29175 9.62501 2.90591 9.62501 3.66675V4.29008C7.00334 4.91341 5.50001 7.26008 5.50001 10.0834V14.6667L3.66667 16.5001V17.4167H18.3333V16.5001L16.5 14.6667ZM11 20.1667C12.0083 20.1667 12.8333 19.3417 12.8333 18.3334H9.16667C9.16667 19.3417 9.99167 20.1667 11 20.1667ZM7.33334 15.5834H14.6667V10.0834C14.6667 7.81008 13.2825 5.95841 11 5.95841C8.71751 5.95841 7.33334 7.81008 7.33334 10.0834V15.5834ZM6.94834 3.74008L5.63751 2.42925C3.43751 4.10675 1.98917 6.69175 1.86084 9.62508H3.69417C3.83167 7.19591 5.07834 5.06925 6.94834 3.74008ZM20.1392 9.62508H18.3058C18.1683 7.19591 16.9125 5.06925 15.0608 3.74008L16.3625 2.42925C18.5533 4.10675 20.0017 6.69175 20.1392 9.62508Z"
      />
    </svg>
  );
}
