import {
  TopPanelMenuComponent,
  TopPanelMenuMobileButtonComponent,
} from "@patientmpower/spiro";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { IRoutePattern } from "../../../@types/Route";
import { UserSettingsDesktop } from "../../../components/UserSettings/UserSettings";
import { useAuth } from "../../../hooks/useAuth";
import { useChildRoutes } from "../../../hooks/useChildRoutes";
import useIsMobile from "../../../hooks/useIsMobile";
import { useLeftPanel } from "../../../hooks/useLeftPanel";
import { mainRoutes } from "../../../routes/main.routes";
import { LeftPanelMenu } from "../LeftPanelMenu";
import { LeftPanelUserSettings } from "../LeftPanelUserSettings";
import { Link } from "../Link";
import {
  ContentContainer,
  NavContentContainer,
  TopPanelItemsContainerDesktop,
} from "./DashboardLayout.styles";

export function DashboardLayout() {
  const { childRoutes } = useChildRoutes();

  const { isAuthenticated } = useAuth();
  const {
    isMobileLeftPanelMenuOpen,
    setIsMobileLeftPanelMenuOpen,
    isMobileUserSettingsMenuOpen,
    setIsMobileUserSettingsMenuOpen,
  } = useLeftPanel();
  const { isMobile } = useIsMobile();

  const navigate = useNavigate();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const handleOnChangeMobileLeftPanelVisibility = () => {
    if (isMobileUserSettingsMenuOpen) {
      setIsMobileUserSettingsMenuOpen(false);
    } else {
      setIsMobileLeftPanelMenuOpen(
        (prevMobileLeftPanelMenuState) => !prevMobileLeftPanelMenuState
      );
    }
  };

  const renderTopBarMenuRoutes = () => {
    return mainRoutes
      .filter(({ disabled }) => !disabled)
      .map(({ path, name }) => (
        <Link key={`${Math.random()}-${path}`} to={path}>
          {name}
        </Link>
      ));
  };

  const renderLeftPanelMenuByDevice = (): IRoutePattern[] => {
    return isMobile ? mainRoutes : childRoutes;
  };

  return (
    <>
      <TopPanelMenuComponent onLogoClick={() => navigate("/")}>
        <NavContentContainer>
          <TopPanelItemsContainerDesktop>
            {renderTopBarMenuRoutes()}
          </TopPanelItemsContainerDesktop>

          <TopPanelMenuMobileButtonComponent
            isMobileLeftPanelMenuOpen={isMobileLeftPanelMenuOpen}
            onClick={handleOnChangeMobileLeftPanelVisibility}
          />
        </NavContentContainer>

        <UserSettingsDesktop />
      </TopPanelMenuComponent>

      <LeftPanelMenu
        items={renderLeftPanelMenuByDevice()}
        onLeftPanelMenuItemClick={handleOnChangeMobileLeftPanelVisibility}
      />

      <LeftPanelUserSettings />

      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </>
  );
}
