import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";

import {
  usePatientInfo,
  usePatientMonitoringInfo,
} from "../../../../hooks/queries/patients";
import useIsMobile from "../../../../hooks/useIsMobile";
import { usePatientPage } from "../../../../hooks/usePatientPage";
import { getShortFormattedDate } from "../../../../utils/dateFormatter";
import {
  Text,
  Title,
  Container,
  DesktopContainer,
  TitleContainer,
  PatientCard,
  MobileTitleContainer,
} from "./PatientSubNav.styles";

dayjs.extend(relativeTime);

type PatientSubNavProps = {
  isLateralPatientMenuOpenOnDesktop?: boolean;
};

export function PatientSubNav({
  isLateralPatientMenuOpenOnDesktop,
}: PatientSubNavProps) {
  const { isMobile } = useIsMobile();
  const [patientCards, setPatientCards] = useState<
    Array<{ name: string; description: string }>
  >([]);

  const { patientId } = useParams();

  const { globalDateFilter } = usePatientPage();

  const { patient } = usePatientInfo({ patientId });
  const { patientMonitoringInfo } = usePatientMonitoringInfo({ patientId });

  useEffect(() => {
    if (!patient && !patientMonitoringInfo) return;

    setPatientCards([
      {
        name: "DOB",
        description: patient.birthDate
          ? getShortFormattedDate(patient.birthDate)
          : "-",
      },
      {
        name: "Hospital ID",
        description: patient?.hospitalId || "-",
      },
      {
        name: "Email",
        description: patient?.user?.email || "-",
      },
      {
        name: "Last Review",
        description: patientMonitoringInfo?.lastestReview || "-",
      },
      {
        name: "Last Reviewed By",
        description: patientMonitoringInfo?.lastestReviewedBy || "-",
      },
      {
        name: "Phone",
        description: patient?.user?.phoneNumber || "-",
      },
      {
        name: "Hospital",
        description: patientMonitoringInfo?.hospitalName || "-",
      },
      {
        name: "Last Used",
        description: patient.user?.lastUsed || "-",
      },
    ]);
  }, [patient, patientMonitoringInfo]);

  const formattedDateRange = `${getShortFormattedDate(
    globalDateFilter.fromDate.format()
  )} - ${getShortFormattedDate(globalDateFilter.toDate.format())}`;

  if (isMobile) {
    const mobileTopHeaderSize = 69;
    const mobileBottomHeaderSize = 87;

    return (
      <BottomSheet
        open
        blocking={false}
        skipInitialTransition
        style={{ zIndex: "1000px" }}
        defaultSnap={() => mobileBottomHeaderSize}
        snapPoints={({ maxHeight }) => [
          mobileBottomHeaderSize,
          maxHeight - mobileTopHeaderSize,
        ]}
        header={
          <MobileTitleContainer>
            <Title>
              Ellis Boyd Redding <span>•</span>
            </Title>

            <Title>
              {patient?.birthDate &&
                dayjs(patient.birthDate).fromNow(true).split(" ")[0]}
            </Title>

            <Title>
              {patient.user?.gender && patient.user.gender.toUpperCase()}
            </Title>
          </MobileTitleContainer>
        }
      >
        {patientCards.map((card, index) => (
          <PatientCard key={`${card.description}_${index}`}>
            <p>
              <strong>{card.name}</strong>
            </p>
            <p>{card.description}</p>
          </PatientCard>
        ))}
      </BottomSheet>
    );
  }

  return (
    <DesktopContainer
      isLateralPatientMenuOpenOnDesktop={isLateralPatientMenuOpenOnDesktop}
    >
      <Container>
        <TitleContainer>
          <Title>{patient.user?.name}</Title>

          <span>•</span>

          <Title>
            {patient?.birthDate &&
              dayjs(patient.birthDate).fromNow(true).split(" ")[0]}
          </Title>

          <Title>
            {patient.user?.gender && patient.user.gender.toUpperCase()}
          </Title>
        </TitleContainer>

        <Text>{formattedDateRange}</Text>
      </Container>
    </DesktopContainer>
  );
}
