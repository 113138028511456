import Cookies from "js-cookie";
import { useState } from "react";

import { COOKIE_AUTH_NAME } from "../constants/authConstants";

export function useToken() {
  const [token, setStoredToken] = useState(() => {
    const token = Cookies.get(COOKIE_AUTH_NAME);

    if (token) {
      Cookies.set(COOKIE_AUTH_NAME, token, { expires: 30 });
    }

    return token;
  });

  const setToken = (token: string) => {
    Cookies.set(COOKIE_AUTH_NAME, token, { expires: 30 });
    setStoredToken(token);
  };

  return { token, setToken };
}
