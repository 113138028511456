import axios from "axios";
import Cookies from "js-cookie";

import { COOKIE_AUTH_NAME } from "../constants/authConstants";

const checkIfUserIsAuthenticated = ({ status }: { status: number }) => {
  const isUserUnauthenticated = status === 401;

  if (isUserUnauthenticated) {
    Cookies.remove(COOKIE_AUTH_NAME);

    window.location.assign("/login");
  }
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_CMS_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    TIMEZONE: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

api.interceptors.request.use((request) => {
  const userToken = Cookies.get(COOKIE_AUTH_NAME);

  if (request.headers) {
    if (userToken) {
      Cookies.set(COOKIE_AUTH_NAME, userToken, { expires: 30 });

      request.headers.Authorization = `Token ${userToken}`;
    }
  }

  return request;
});

api.interceptors.response.use(
  (response) => {
    checkIfUserIsAuthenticated({ status: response.status });

    return response;
  },
  (error) => {
    checkIfUserIsAuthenticated({ status: error.response.status });

    return error;
  }
);
