import { AxiosResponse } from "axios";

import { api } from "./api";

export const spirometryService = {
  async getPatientSpirometryMeasurementsValuesById(
    patientId: string | undefined
  ): Promise<AxiosResponse> {
    const url = `/api/spirometry`;

    const patientSpirometryMeasurements = await api.get(url, {
      params: { patient_id: patientId },
    });

    return patientSpirometryMeasurements;
  },

  async getSpirometryVolumeFlow(spirometryId: number): Promise<AxiosResponse> {
    const url = `/api/spirometry/${spirometryId}/volume_flow/`;
    const spirometryVolumeFlow = await api.get(url);

    return spirometryVolumeFlow;
  },

  async getSpirometryTimeVolume(spirometryId: number): Promise<AxiosResponse> {
    const url = `/api/spirometry/${spirometryId}/time_volume/`;
    const spirometryVolumeFlow = await api.get(url);

    return spirometryVolumeFlow;
  },

  async getArtiqSpitormetryData(spirometryId: number): Promise<AxiosResponse> {
    const url = `/api/spirometry-v2/${spirometryId}/`;
    const spirometryArtiqSpirometryData = await api.get(url);

    return spirometryArtiqSpirometryData;
  },
};
