import { SkeletonLoading } from "@patientmpower/spiro";

import useIsMobile from "../../hooks/useIsMobile";
import {
  Container,
  Header,
  MobileContainer,
  WhiteRow,
} from "./TableSkeleton.styles";

type TableSkeletonProps = {
  columnsNumber: number;
};

export function TableSkeleton({ columnsNumber }: TableSkeletonProps) {
  const { isMobile } = useIsMobile();

  if (isMobile) {
    return (
      <MobileContainer>
        {new Array(15).fill("row").map((value, index) => (
          <div key={`${index}_${value}`}>
            <WhiteRow />
            <SkeletonLoading height="62px" width="100%" borderRadius="0px" />
          </div>
        ))}
      </MobileContainer>
    );
  }

  return (
    <Container>
      <Header>
        {new Array(columnsNumber).fill(columnsNumber).map((value, index) => (
          <SkeletonLoading
            key={`${value}_${index}`}
            height="26px"
            width="104px"
            borderRadius="8px"
          />
        ))}
      </Header>

      <div>
        {new Array(20).fill("row").map((value, index) => (
          <div key={`${value}_${index}`}>
            <WhiteRow />
            <SkeletonLoading height="42px" width="100%" borderRadius="0px" />
          </div>
        ))}
      </div>
    </Container>
  );
}
