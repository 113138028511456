import { styled } from "@patientmpower/spiro";

export const SpirometryContainer = styled("div", {
  height: "100%",
  width: "100%",

  padding: "24px 18px 18px 48px",

  borderLeft: "1px solid $gray-30",
});

export const ChartContainer = styled("div", {
  gap: "20px",

  display: "flex",
  flexDirection: "column",
});

export const ContentContainer = styled("div", {
  display: "flex",
  gap: "64px",
});

export const SubtitleGradeContainer = styled("div", {
  display: "flex",
  alignItems: "center",

  minHeight: "20px",

  marginTop: "4px",
  marginBottom: "12px",

  gap: "10px",
});

export const ArtiQContainer = styled("div", {
  display: "flex",
  flexDirection: "column",

  gap: "60px",
});
