import { styled } from "@patientmpower/spiro";
import * as Popover from "@radix-ui/react-popover";

export const StyledPopoverTrigger = styled(Popover.Trigger, {
  position: "absolute",

  top: 8,
  right: 42,

  border: "none",
  backgroundColor: "transparent",
});

export const StyledPopoverContent = styled(Popover.Content, {
  zIndex: 3000,
});

export const OptionsContainer = styled("div", {
  marginRight: "164px",

  background: "$white",

  borderRadius: "12px",
  border: "1px solid $gray-50",
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12)",

  width: "208px",
  // height: "273px",
});

export const OptionItem = styled("div", {
  padding: "16px 28px",

  display: "flex",

  alignItems: "center",
  justifyContent: "space-between",

  p: {
    fontFamily: "$openSans",
    fontWeight: 400,
    fontSize: "$text-sm",

    lineHeight: "16px",
    color: "$charcoal",
  },
});
