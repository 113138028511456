import { createColumn } from "@patientmpower/spiro";

export const desktopTableColumns = [
  createColumn({
    keyName: "name",
    header: () => "Name",
    cell: (info) => info.renderValue(),
    size: 150,
  }),
  createColumn({
    keyName: "hospitalId",
    header: () => "Hospital ID",
    cell: (info) => info.renderValue(),
    size: 90,
  }),
  createColumn({
    keyName: "id",
    header: () => "pMp ID",
    cell: (info) => info.renderValue(),
    size: 70,
  }),
  createColumn({
    keyName: "alerts",
    header: () => "Alerts",
    cell: (info) => info.renderValue(),
    size: 70,
  }),
  createColumn({
    keyName: "signUpDate",
    header: () => "Sign up date",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "lastUsed",
    header: () => "Last used",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "lastReviewedBy",
    header: () => "Last reviewed by",
    cell: (info) => info.renderValue(),
  }),
  createColumn({
    keyName: "action",
    header: () => "",
    cell: (info) => info.renderValue(),
    size: 26,
  }),
];

export const mobileAccordionListColumns = [
  { key: "Hospital ID", value: "hospitalId" },
  { key: "pMp ID", value: "id" },
  { key: "Sign up date", value: "signUpDate" },
  { key: "Hospital ID", value: "hospitalId" },
  { key: "Last reviewed by", value: "lastReviewedBy" },
];
