import { styled } from "@patientmpower/spiro";

export const Container = styled("div", {
  height: "100%",
  width: "100%",

  background: "$white",

  border: "1px solid $gray-40",
  borderRadius: "$3",

  overflow: "hidden",
});

export const MobileContainer = styled("div", {
  height: "85vh",
  overflow: "hidden",
});

export const Header = styled("div", {
  height: "82px",

  display: "flex",
  justifyContent: "space-between",

  padding: "35px 38px 20px 38px",
  borderBottom: "1px solid $gray-30",

  marginBottom: "8px",
});

export const WhiteRow = styled("div", {
  height: "42px",
  width: "100%",

  backgroundColor: "$white",

  "@sm": {
    height: "62px",
  },
});
