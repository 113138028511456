import { styled } from "@patientmpower/spiro";

export const Container = styled("aside", {
  zIndex: 1100,

  backgroundColor: "$white",

  paddingTop: "18px",
  paddingLeft: "16px",
  paddingRight: "16px",

  height: "calc(100% - 62px)",
  width: "88px",

  borderRight: "1px solid $gray-40",

  position: "fixed",
  top: 62,

  overflow: "hidden",
  transition: "width .4s",

  "&:hover": {
    width: "248px",
  },

  "@sm": {
    width: 0,
    height: "100%",
    border: 0,

    paddingTop: "66px",
    paddingLeft: "0px",
    paddingRight: "0px",
    transition: "ease 500ms",
  },

  variants: {
    mobileLeftPanelMenuOpen: {
      true: {
        "@sm": {
          width: "100%",

          paddingLeft: "44px",
          paddingRight: "44px",

          "&:hover": {
            width: "100%",
          },
        },
      },
    },
  },
});

export const IconContainer = styled("div", {
  width: "22px",
  height: "22px",
});

export const ListItem = styled("li", {
  listStyle: "none",
  overflow: "hidden",

  borderRadius: "$3",
  marginBottom: "4px",

  display: "flex",
  position: "relative",

  a: {
    width: "100%",
    padding: "6px 16px",

    border: "2px solid transparent",
    borderRadius: "$3",

    gap: "16px",
    display: "flex",
    alignItems: "center",

    textDecoration: "none",
    color: "$gray-90",

    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "$openSans",
    fontWeight: 400,
    fontSize: "$text-xl",

    lineHeight: "21px",

    svg: {
      fill: "$gray-90",
    },

    "&.active": {
      fontWeight: 600,
      color: "$gray-100",

      backgroundColor: "$gray-30",
      border: "2px solid $gray-30",

      svg: {
        fill: "$gray-100",
      },
    },

    "&:hover": {
      backgroundColor: "$gray-30",
      border: "2px solid $gray-30",
    },

    "&:focus-visible": {
      outline: "none",
      border: "2px solid $primary-30",
    },
  },

  variants: {
    disabled: {
      true: {
        "&:hover": {
          backgroundColor: "white",
        },

        "&.active": {
          backgroundColor: "white",
        },
      },
    },
  },
});

export const DisabledOverlay = styled("div", {
  width: "100%",
  height: "100%",

  position: "absolute",
  top: 0,
  left: 0,

  opacity: 0.6,
  zIndex: 500,

  cursor: "not-allowed",
  backgroundColor: "$white",
});

export const List = styled("ul", {});

export const Divider = styled("hr", {
  border: "1px solid $gray-50",
  margin: "28px 0",

  "@sm": {
    margin: "22px 16px",
    maxWidth: 160,
  },
});

export const BothDevicesSection = styled("section", {});

export const MobileSection = styled("section", {
  display: "none",

  "@sm": {
    display: "block",
  },
});
