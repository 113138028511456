import { FlagFilled } from "../../../../../../assets/icons/FlagFilled";
import { FlagOutlined } from "../../../../../../assets/icons/FlagOutlined";
import { CustomIconButton } from "../../../../../../components/CustomIconButton";

type FlagPatientButtonProps = {
  flagged: boolean;
  onClick: (event: any) => void;
  isMobile?: boolean;
};

export function FlagPatientButton({
  flagged,
  onClick,
  isMobile,
}: FlagPatientButtonProps) {
  if (flagged) {
    return (
      <CustomIconButton onClick={onClick}>
        <FlagFilled />
      </CustomIconButton>
    );
  }

  return (
    <CustomIconButton
      onClick={onClick}
      className={!isMobile ? "actionContainer" : ""}
      style={{ display: `${!isMobile ? "none" : "flex"}` }}
    >
      <FlagOutlined />
    </CustomIconButton>
  );
}

FlagPatientButton.defaultProps = {
  isMobile: false,
};
