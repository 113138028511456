export function ArrowLeft({ ...rest }) {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0.190381 10.4568C-0.0634604 10.6954 -0.0634604 11.0824 0.190381 11.321C0.444221 11.5597 0.855779 11.5597 1.10962 11.321L6.30962 6.43212C6.56346 6.19347 6.56346 5.80653 6.30962 5.56788L1.10962 0.67899C0.855779 0.440337 0.444221 0.440337 0.190381 0.67899C-0.0634604 0.917644 -0.0634604 1.30458 0.190381 1.54323L4.93071 5.99995L0.190381 10.4568Z"
        fill="#3C3C3C"
      />
    </svg>
  );
}
