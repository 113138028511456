import { randomChartColor } from "../utils/random";

export const measurementsConfigs = {
  PULSE_OX: {
    type: "pulse_ox",
    title: "Oxygen Saturation",
    unit: "%",
    color: randomChartColor(),
  },
  TEMPERATURE: {
    type: "temperature",
    title: "Temperature",
    unit: "°F",
    color: randomChartColor(),
  },
  BREATHLESSNESS: {
    type: "breathlessness",
    title: "Breathlessness",
    color: randomChartColor(),
  },
  DIARRHOEA: {
    type: "diarrhoea",
    title: "Diarrhoea",
    color: randomChartColor(),
  },
  HEART_RATE: {
    type: "heart_rate",
    title: "Heart rate",
    unit: "BPM",
    color: randomChartColor(),
  },
  HEIGHT: {
    type: "height",
    title: "Height",
    unit: "cm",
    color: randomChartColor(),
  },
  WEIGHT: {
    type: "weight",
    title: "Weight",
    unit: "kg",
    color: randomChartColor(),
  },
  FEV_1: {
    type: "fev1",
    title: "FEV 1",
    unit: "L",
    color: randomChartColor(),
    category: "spirometry",
  },
  FEV25_75: {
    type: "fev25_75",
    title: "FEV 25/75",
    unit: "L/s",
    color: randomChartColor(),
    category: "spirometry",
  },
  DYSPNEA: {
    type: "dyspnea",
    title: "Dyspnea",
    color: randomChartColor(),
  },
  SYSTOLIC_BLOOD_PRESSURE: {
    type: "bp_systolic",
    title: "Systolic Blood Pressure",
    unit: "mmHg",
    color: randomChartColor(),
  },
  DIASTOLIC_BLOOD_PRESSURE: {
    type: "bp_diastolic",
    title: "Diastolic Blood Pressure",
    unit: "mmHg",
    color: randomChartColor(),
  },
  HEMOGLOBIN: {
    type: "hemoglobin",
    title: "Hemoglobin",
    unit: "g/dL",
    color: randomChartColor(),
  },
  BLOOD_GLUCOSE: {
    type: "blood_glucose",
    title: "Blood Glucose",
    unit: "mmol/L",
    color: randomChartColor(),
  },
  CNI_TROUGH: {
    type: "cni_trough",
    title: "CNI Trough",
    unit: "ng/ml",
    color: randomChartColor(),
  },
  BARTHEL_INDEX: {
    type: "barthel_index",
    title: "Modified Barthel Index",
    color: randomChartColor(),
  },
  ROCKWOOD_INDEX: {
    type: "rockwood_index",
    title: "Rockwood Fragility Index",
    color: randomChartColor(),
  },
  RESPIRATORY_RATE: {
    type: "respiratory_rate",
    title: "Respiratory Rate",
    color: randomChartColor(),
  },
  OXYGEN_FLOW_RATE: {
    type: "oxygen_rate",
    title: "Oxygen Flow Rate",
    unit: "L",
    color: randomChartColor(),
  },
  FVC: {
    title: "FVC",
    type: "fvc",
    unit: "L",
    color: randomChartColor(),
    category: "spirometry",
  },
  FEV1_FVC: {
    title: "FEV1/FVC",
    type: "fev1_fvc",
    unit: "L",
    color: randomChartColor(),
    category: "spirometry",
  },
  CREATININE: {
    title: "Creatinine",
    type: "creatinine",
    unit: "L",
    color: randomChartColor(),
  },
  GFR: {
    title: "GFR",
    type: "gfr",
    color: randomChartColor(),
  },
  WHITE_BLOOD_CELLS: {
    title: "White Blood Cells",
    type: "white_cells",
    unit: "/L",
    color: randomChartColor(),
  },
  PSA: {
    title: "PSA",
    type: "psa",
    unit: "ng/ml",
    color: randomChartColor(),
  },
  PHOSPHATE: {
    title: "Phosphate",
    type: "phosphate",
    unit: "mg/dL",
    color: randomChartColor(),
  },
  SPUTUM_VOLUME: {
    title: "Sputum Volume",
    type: "sputum_volume",
    color: randomChartColor(),
  },
  FVC_PERCENT_PREDICTED: {
    title: "Predicted FVC",
    type: "percent_predicted_fvc",
    unit: "%",
    color: randomChartColor(),
    category: "spirometry",
  },
  FEV1_PERCENT_PREDICTED: {
    title: "Predicted FEV1",
    type: "percent_predicted_fev1",
    unit: "%",
    color: randomChartColor(),
    category: "spirometry",
  },
};

export const spirometryMeasurementsValues = [
  "percent_predicted_fev1",
  "percent_predicted_fvc",
  "fev1",
  "fev25_75",
  "fvc",
  "fev1_fvc",
];
