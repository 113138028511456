import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

type UserPreferencesContextData = {
  preferences: UserPreferences;
  changeUserPreferences: (userPreferences: UserPreferences) => void;
};

type UserPreferencesProviderProps = {
  children: ReactNode;
};

type UserPreferences = {
  scaleYAxis: boolean;
};

export const UserPreferencesContext = createContext(
  {} as UserPreferencesContextData
);

export function UserPreferencesProvider({
  children,
}: UserPreferencesProviderProps) {
  const [userPreferences, setUserPreferences] = useState<UserPreferences>(
    () => {
      const userPreferencesStored = localStorage.getItem("userPreferences");

      let userPreferencesStoredParsed = null;
      try {
        userPreferencesStoredParsed =
          userPreferencesStored && JSON.parse(userPreferencesStored);
      } catch (error) {
        console.log("error", error);
      }

      return (
        userPreferencesStoredParsed || ({ scaleYAxis: true } as UserPreferences)
      );
    }
  );

  useEffect(() => {
    localStorage.setItem("userPreferences", JSON.stringify(userPreferences));
  }, [userPreferences]);

  const handleOnChangeUserPreferences = (userPreferences: UserPreferences) => {
    setUserPreferences(userPreferences);
  };

  const userPreferencesValues = useMemo(() => {
    return {
      preferences: userPreferences,
      changeUserPreferences: handleOnChangeUserPreferences,
    };
  }, [userPreferences]);

  return (
    <UserPreferencesContext.Provider value={userPreferencesValues}>
      {children}
    </UserPreferencesContext.Provider>
  );
}
