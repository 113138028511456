import { Counter } from "./AlertsCounter.styles";

type AlertsCounterProps = React.ComponentProps<typeof Counter> & {
  children: React.ReactNode;
};

export function AlertsCounter({ children, ...rest }: AlertsCounterProps) {
  let numberOfAlerts = children?.toString();
  let wideAlertSize;

  if (numberOfAlerts) {
    const parsedAlerts = Number(numberOfAlerts);

    if (Number.isNaN(parsedAlerts) && numberOfAlerts.length >= 2) {
      wideAlertSize = {
        width: `${26 + (numberOfAlerts.length - 1) * 9}px`,
      };
    } else if (parsedAlerts > 9) {
      numberOfAlerts = "9+";
    }
  }

  return (
    <Counter style={wideAlertSize} {...rest}>
      {numberOfAlerts}
    </Counter>
  );
}
