import { AxiosResponse } from "axios";

import { IPatientListVariants } from "../@types/Patient";
import { patientListVariants } from "../constants/patientListVariants";
import { api } from "./api";

export const patientService = {
  async getPatients({ variant }: IPatientListVariants): Promise<AxiosResponse> {
    const url =
      variant === "archived"
        ? "/api/services/patients/archived-patients/"
        : "/api/services/patients/patients/";

    const urlParamsToParse = [
      "id",
      "name",
      "created",
      "flagged",
      "last_used",
      "alerts_display",
      "hospital_patient_id",
      "lastest_review_event",
    ];

    const urlParamsParsed = new URLSearchParams();
    urlParamsToParse.forEach((currentUrlParamToParse) =>
      urlParamsParsed.append("return_fields", currentUrlParamToParse)
    );

    if (variant === patientListVariants.FLAGGED) {
      urlParamsParsed.append("flagged", "true");
    }

    if (variant === patientListVariants.ALERTS) {
      urlParamsParsed.append("alerts", "true");
    }

    const patientsResponse = await api.get(url, {
      params: urlParamsParsed,
    });

    return patientsResponse;
  },

  async getPatientById(id: string): Promise<AxiosResponse> {
    const url = `/api/patient/${id}`;
    const patient = await api.get(url);

    return patient;
  },

  async flagPatient({
    id,
    flagged,
  }: {
    id: string | number;
    flagged: boolean;
  }): Promise<AxiosResponse> {
    const url = `/api/services/patients/patients/${id}/update_flagged/`;
    const responseFromAPI = await api.patch(url, {
      flagged: flagged ? "true" : "false",
    });

    return responseFromAPI;
  },

  async getPatientMonitoringInfo(id: string): Promise<AxiosResponse> {
    const url = `/monitoring-portal/api/patients/${id}`;
    const patient = await api.get(url);

    return patient;
  },

  async getPatientAlerts(id: string): Promise<AxiosResponse> {
    const url = `api/triggered-measurement-alerts`;
    const patient = await api.get(url, { params: { patient_id: id } });

    return patient;
  },
};
