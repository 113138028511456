import { useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain, AxisInterval } from "recharts/types/util/types";

import { CustomTooltip } from "../ChartCard";
import { ChartConfig, ChartData } from "../ChartCard/ChartCard.types";
import { CustomDot } from "./CustomDot";

type MeasurementPayloadType = {
  fvc: number;
  time: string;
  unformattedTime: string;
  spirometryTestIds: number[];

  cx: number;
  cy: number;
};

type ModalChartProps = {
  xConfig?: {
    label?: any;
    dataKey?: string;
    axisSize?: number;
    minTickGap?: number;
    interval?: AxisInterval;
    allowDecimals?: boolean;
    type?: "number" | "category";
    ticks?: Array<number | string>;
  };
  yConfig: {
    axisSize?: number;
    domain?: AxisDomain;
    label?: any;
  };
  data: ChartData[];
  width: string | number;
  height: string | number;
  disableTooltip?: boolean;
  chartConfig: ChartConfig[];
  onRowClick?: (payload: MeasurementPayloadType) => void;
};

export function ModalChart({
  data,
  width,
  height,
  xConfig,
  yConfig,
  onRowClick,
  chartConfig,
  disableTooltip,
}: ModalChartProps) {
  const [selectedPoint, setSelectedPoint] =
    useState<MeasurementPayloadType | null>(null);

  const tickXFormatter = (value: string) => {
    if (!data || !data.length) return "";
    if (xConfig?.dataKey) return value;

    return value && value.split(",")[1];
  };

  const handleOnClickOverActiveDots = (selectedPointInfo: any) => {
    if (onRowClick) {
      setSelectedPoint(selectedPointInfo);
      onRowClick(selectedPointInfo as MeasurementPayloadType);
    }
  };

  return (
    <ResponsiveContainer width={width} height={height}>
      <LineChart margin={{ right: 8, top: 8 }} data={data}>
        {!disableTooltip && (
          <Tooltip
            cursor={false}
            content={<CustomTooltip />}
            wrapperStyle={{ outline: "none" }}
          />
        )}

        <CartesianGrid strokeDasharray="3 3" />

        <YAxis
          tickMargin={8}
          stroke="#D8D8D8"
          allowDecimals={false}
          label={yConfig.label}
          domain={yConfig.domain}
          width={yConfig.axisSize || 48}
          tick={{ fill: "#727272", fontSize: "12px", fontWeight: 400 }}
        />

        <XAxis
          tickMargin={8}
          stroke="#D8D8D8"
          type={xConfig?.type}
          label={xConfig?.label}
          ticks={xConfig?.ticks}
          tickFormatter={tickXFormatter}
          allowDuplicatedCategory={false}
          height={xConfig?.axisSize || 48}
          dataKey={xConfig?.dataKey || "time"}
          allowDecimals={xConfig?.allowDecimals}
          minTickGap={xConfig?.minTickGap || 100}
          interval={xConfig?.interval || "preserveStart"}
          tick={{ fill: "#727272", fontSize: "12px", fontWeight: 400 }}
        />

        {chartConfig.map((currentChartConfig: ChartConfig, index: number) => (
          <Line
            key={`${currentChartConfig.yKey}_${index}_${currentChartConfig.color}`}
            connectNulls
            activeDot={
              currentChartConfig.dot && (
                <CustomDot
                  isActive
                  selectedValue={selectedPoint}
                  color={currentChartConfig.color}
                  onDotClick={handleOnClickOverActiveDots}
                />
              )
            }
            isAnimationActive={false}
            type={currentChartConfig?.lineType || "monotone"}
            strokeWidth={currentChartConfig.stroke || 1.4}
            dot={
              currentChartConfig.dot && (
                <CustomDot
                  selectedValue={selectedPoint}
                  color={currentChartConfig.color}
                  onDotClick={handleOnClickOverActiveDots}
                />
              )
            }
            dataKey={currentChartConfig.yKey}
            stroke={currentChartConfig.color}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}
