import mixpanel from "mixpanel-browser";

const env_check = process.env.NODE_ENV === "development";

if (!env_check) {
  const mixpanelKey = process.env.REACT_APP_MIXPANEL_TOKEN || "";

  mixpanel.init(mixpanelKey);
}

export const mixpanelActions = {
  identify: (id: string) => {
    if (!env_check) {
      mixpanel.identify(id);
    }
  },
  alias: (id: string) => {
    if (!env_check) {
      mixpanel.alias(id);
    }
  },
  track: (name: string, props?: any) => {
    if (!env_check) {
      mixpanel.track(name, props);
    }
  },
};
