import { Table } from "@patientmpower/spiro";

import { TableSkeleton } from "../../../../../../components/TableSkeleton";
import { desktopTableColumns } from "./tableColumns";

type PatientAlertsTableProps = {
  content: any[];
  isLoading: boolean;
};

export function PatientAlertsTable({
  content,
  isLoading,
}: PatientAlertsTableProps) {
  if (isLoading) {
    return <TableSkeleton columnsNumber={7} />;
  }

  const handleOnRowClick = () => {};

  const highlightedRows: any[] = [];
  content.forEach((row) => {
    highlightedRows.push(row.alertStatus === "Unresolved");
  });

  return (
    <Table
      data={content}
      highlightColor="#FAD1D9"
      columns={desktopTableColumns}
      onRowClick={handleOnRowClick}
      highlightedRows={highlightedRows}
    />
  );
}
