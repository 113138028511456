import { createContext, ReactNode, useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { IRoutePattern } from "../@types/Route";
import { routesWithChildRoutes } from "../routes/routes";

type IChildRoutesContextData = {
  childRoutes: Array<IRoutePattern>;
};

type IChildRoutesProvider = {
  children: ReactNode;
};

export const ChildRoutesContext = createContext({} as IChildRoutesContextData);

export function ChildRoutesProvider({ children }: IChildRoutesProvider) {
  const location = useLocation();

  const valuesToShare = useMemo(() => {
    /**
     * This piece of code will match the routes that have the same
     * child routes.
     */
    const activeChildRoute =
      routesWithChildRoutes.find(({ paths }) => {
        return paths.find((path) =>
          matchPath(
            {
              path,
            },
            location.pathname
          )
        );
      })?.childRoutes || [];

    return {
      childRoutes: activeChildRoute,
    };
  }, [location]);

  return (
    <ChildRoutesContext.Provider value={valuesToShare}>
      {children}
    </ChildRoutesContext.Provider>
  );
}
