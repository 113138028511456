import { useQuery } from "@tanstack/react-query";

import {
  IPatient,
  IPatientAlertInfo,
  IPatientInfo,
  IPatientListVariants,
  IPatientMonitoringInfo,
} from "../../@types/Patient";
import { patientService } from "../../services/patientService";
import { getLongFormattedDate } from "../../utils/dateFormatter";
import { tableCellNullValueParser } from "../../utils/tableDataFormatter";

export function usePatientList({ variant }: IPatientListVariants) {
  const { data, isLoading, isError } = useQuery(
    [`patient-list-${variant}`, variant],
    async () => {
      const { data } = await patientService.getPatients({ variant });

      // TODO: add specific type for the API response when we move to the 2.0 API.
      const patientsParsed = data.map((patient: any) => {
        const signUpDate = tableCellNullValueParser(
          patient?.created,
          getLongFormattedDate
        );

        const lastUsed = tableCellNullValueParser(
          patient?.last_used,
          getLongFormattedDate
        );

        const hospitalId = tableCellNullValueParser(
          patient?.hospital_patient_id
        );

        const lastReviewedBy = tableCellNullValueParser(
          patient?.lastest_review_event?.reviewed_by_name,
          getLongFormattedDate
        );

        return {
          lastUsed,
          hospitalId,
          signUpDate,
          id: patient.id,
          lastReviewedBy,
          name: patient.name,
          flagged: patient.flagged,
          alerts: patient?.alerts_display,
        };
      });

      return { patients: patientsParsed as IPatient[] };
    },
    { refetchOnMount: "always" }
  );

  return { patients: data?.patients, isLoading, isError };
}

export function usePatientInfo({
  patientId,
}: {
  patientId: string | undefined;
}) {
  const { data, isFetching, isError } = useQuery(
    [`patient-info-${patientId}`, patientId],
    async () => {
      if (!patientId) return { condition: "" } as unknown as IPatientInfo;

      const { data } = await patientService.getPatientById(patientId);

      return {
        id: data?.id,
        birthDate: data?.dob,
        condition: data?.conditions[0].name,
        hospitalId: data?.hospital_patient_id,
        hospitalPatientId: data?.hospital_patient_id,
        user: {
          name: `${data?.user.first_name} ${data?.user.last_name}`,
          gender: data?.sex,
          email: data?.user.email,
          phoneNumber: data?.user.phoneNumber,
          lastUsed: data?.user.last_used
            ? getLongFormattedDate(data?.user.last_used)
            : null,
        },
      } as IPatientInfo;
    }
  );

  return {
    patient: data as IPatientInfo,
    isFetching,
    isError,
  };
}

export function usePatientAlerts({
  patientId,
}: {
  patientId: string | undefined;
}) {
  const { data, isFetching, isError } = useQuery(
    [`patient-alerts-${patientId}`, patientId],
    async () => {
      if (!patientId) return {} as unknown as IPatientAlertInfo;

      const { data } = await patientService.getPatientAlerts(patientId);

      return data.map((currentAlert: any) => {
        const timeParsed = getLongFormattedDate(currentAlert.time);

        const resolvedByFormatted = tableCellNullValueParser(
          currentAlert.resolved_by_staff,
          "-"
        );

        return {
          time: timeParsed,
          id: currentAlert.id,
          resolvedBy: resolvedByFormatted,
          resolved: currentAlert?.resolved_at,
          type: currentAlert?.trigger_measurement,
          trigger: currentAlert?.trigger_short_summary,
          timeFrame: currentAlert?.trigger_timeframe_days,
        } as unknown as IPatientAlertInfo[];
      });
    },
    { refetchOnMount: "always" }
  );

  return { isFetching, isError, alerts: data };
}

export function usePatientMonitoringInfo({
  patientId,
}: {
  patientId: string | undefined;
}) {
  const { data, isFetching, isError } = useQuery(
    [`patient-monitoring-info-${patientId}`, patientId],
    async () => {
      if (!patientId) return {} as unknown as IPatientMonitoringInfo;

      const { data } = await patientService.getPatientMonitoringInfo(patientId);

      return {
        hospitalName: data?.hospital_name,
        lastestReviewedBy: data?.latest_review_event?.reviewed_by_name,
        lastestReview: data?.latest_review_event
          ? getLongFormattedDate(data?.latest_review_event.time)
          : null,
      } as IPatientMonitoringInfo;
    }
  );

  return {
    patientMonitoringInfo: data as IPatientMonitoringInfo,
    isFetching,
    isError,
  };
}
