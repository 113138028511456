import { styled } from "@patientmpower/spiro";

export const PatientListTableContainer = styled("div", {
  marginTop: "$4",

  position: "relative",
  height: "calc(100vh - 200px)",

  "@sm": {
    marginTop: 0,
  },
});
