import { useAuth } from "../../../hooks/useAuth";
import { useLeftPanel } from "../../../hooks/useLeftPanel";
import {
  LeftPanelUserSettingsContainer,
  UserSettingOption,
} from "./LeftPanelUserSettings.style";

export function LeftPanelUserSettings() {
  const { isMobileUserSettingsMenuOpen } = useLeftPanel();
  const { signOut } = useAuth();

  const handleSignOutClick = () => signOut();

  return (
    <LeftPanelUserSettingsContainer isOpen={isMobileUserSettingsMenuOpen}>
      <UserSettingOption type="button" onClick={handleSignOutClick}>
        Sign out
      </UserSettingOption>
    </LeftPanelUserSettingsContainer>
  );
}
