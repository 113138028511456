export function Spirometry({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.175 6.58824C17.175 4.88529 15.7897 3.5 14.0868 3.5C12.3838 3.5 10.9985 4.88529 10.9985 6.58824H12.7632C12.7632 5.85588 13.3544 5.26471 14.0868 5.26471C14.8191 5.26471 15.4103 5.85588 15.4103 6.58824C15.4103 7.32059 14.8191 7.91176 14.0868 7.91176H2.17499V9.67647H14.0868C15.7897 9.67647 17.175 8.29118 17.175 6.58824ZM13.2044 15.8529C13.2044 17.3088 12.0132 18.5 10.5573 18.5C9.10146 18.5 7.91028 17.3088 7.91028 15.8529H9.67499C9.67499 16.3382 10.072 16.7353 10.5573 16.7353C11.0426 16.7353 11.4397 16.3382 11.4397 15.8529C11.4397 15.3676 11.0426 14.9706 10.5573 14.9706H2.17499V13.2059H10.5573C12.0132 13.2059 13.2044 14.3971 13.2044 15.8529ZM2.17499 10.5588H16.7338C18.4368 10.5588 19.822 11.9441 19.822 13.6471C19.822 15.35 18.4368 16.7353 16.7338 16.7353V14.9706C17.4662 14.9706 18.0573 14.3794 18.0573 13.6471C18.0573 12.9147 17.4662 12.3235 16.7338 12.3235H2.17499V10.5588Z"
      />
    </svg>
  );
}
