import { MeasurementTypes } from "../../../../@types/Measurements";
import { IPatientInfo } from "../../../../@types/Patient";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useMeasurementsByCondition } from "../../../../hooks/useMeasurementsByCondition";
import { PageTitle } from "../../../../layout/components/PageTitle";
import {
  ChartsContainer,
  MobilePatientPageTitleContainer,
} from "../../PatientPage.styles";
import { MeasurementCard } from "../MeasurementCard";

type LabsSectionProps = {
  patient: IPatientInfo;
};

export function LabsSection({ patient }: LabsSectionProps) {
  const { isMobile } = useIsMobile();

  const { labsResult } = useMeasurementsByCondition({
    condition: patient?.condition,
  });

  return (
    <>
      {!isMobile && <PageTitle>Lab results</PageTitle>}

      <ChartsContainer>
        {isMobile && (
          <MobilePatientPageTitleContainer>
            <PageTitle>Lab results</PageTitle>
          </MobilePatientPageTitleContainer>
        )}

        {labsResult.map((measurement, index) => (
          <MeasurementCard
            key={`${measurement.title}_${measurement.type}_${index}`}
            cardTitle={`${measurement.title} ${
              measurement.unit ? `(${measurement.unit})` : ""
            }`}
            measurementType={measurement.type as MeasurementTypes}
            chartConfig={[
              {
                dot: false,
                lineType: "monotone",
                yKey: measurement.type,
                color: measurement.color,
              },
            ]}
          />
        ))}
      </ChartsContainer>
    </>
  );
}
