import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { AxisDomain } from "recharts/types/util/types";

import { ModalChart } from "../../../../../../components/ModalChart";
import { spirometryMeasurementsValues } from "../../../../../../constants/measurements";
import { useMeasurement } from "../../../../../../hooks/queries/measurements";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import { useUserPreferences } from "../../../../../../hooks/useUserPreferences";
import { getShortFormattedDate } from "../../../../../../utils/dateFormatter";
import { mixpanelActions } from "../../../../../../utils/mixpanel";
import { roundToX } from "../../../../../../utils/unitOperations";
import {
  MeasurementContainer,
  HeaderContainer,
  Title,
  Subtitle,
} from "../../MeasurementModal.styles";
import { MeasurementModalProps } from "../../MeasurementModal.types";
import { CustomOptionsBar, DateFilterOptionType } from "../CustomOptionsBar";
import { dateFilterOptions } from "../CustomOptionsBar/dateFilterOptions";
import { OptionsPopover } from "../OptionsPopover";
import { ModalOptions } from "../OptionsPopover/Options.types";

type MeasurementSectionProps = Omit<
  MeasurementModalProps,
  "spirometryMeasurements"
> & {
  onSelectChartValue?: (selectedChartValue: any) => void;
};

export function MeasurementSection({
  patientId,
  cardTitle,
  chartConfig,
  measurementType,
  onSelectChartValue,
}: MeasurementSectionProps) {
  const { preferences, changeUserPreferences } = useUserPreferences();

  const { widthView } = useIsMobile();

  const [selectedFilterDate, setSelectedFilterDate] =
    useState<DateFilterOptionType>({
      endDate: dayjs(),
      label: dateFilterOptions[4].label,
      startDate: dateFilterOptions[4].value,
    });

  const { measurement, mainMeasurementValues } = useMeasurement({
    patientId,
    zoomedVersion: true,
    type: measurementType,
    toDate: selectedFilterDate.endDate.format("YYYY-MM-DD"),
    fromDate: selectedFilterDate.startDate.format("YYYY-MM-DD"),
  });

  const domainByGlobalPreferences = useMemo(() => {
    if (preferences.scaleYAxis) {
      return [
        (dataMin: number) => Math.floor(dataMin * 0.9),
        (dataMax: number) => roundToX(dataMax * 1.1, 0),
      ] as AxisDomain;
    }

    return [0, (dataMax: number) => roundToX(dataMax * 1.1, 0)] as AxisDomain;
  }, [preferences.scaleYAxis, mainMeasurementValues]);

  const measurementChartWidth = useMemo(() => {
    return widthView > 1530 ? 834 : 664;
  }, [widthView]);

  const formattedDateRange = `${getShortFormattedDate(
    selectedFilterDate.startDate.format()
  )} - ${getShortFormattedDate(selectedFilterDate.endDate.format())}`;

  const handleOnChangeModalOptions = (options: ModalOptions) => {
    mixpanelActions.track(`UserAction: ScaleYAxis Button`);

    changeUserPreferences(options);
  };

  const customChartConfig = chartConfig.map((currentChartConfig) => ({
    ...currentChartConfig,
    dot: spirometryMeasurementsValues.indexOf(measurementType) !== -1,
  }));

  return (
    <MeasurementContainer>
      <OptionsPopover
        initialOptions={preferences}
        onChangeOption={handleOnChangeModalOptions}
      />

      <HeaderContainer>
        <Title>{cardTitle}</Title>
        <Subtitle>{formattedDateRange}</Subtitle>

        <CustomOptionsBar
          onChange={(selectedDateFilter) =>
            setSelectedFilterDate(selectedDateFilter)
          }
          selectedFilterDate={selectedFilterDate}
          cardTitle={cardTitle}
        />
      </HeaderContainer>

      <ModalChart
        onRowClick={
          onSelectChartValue &&
          ((selectedMeasurementData) => {
            onSelectChartValue(selectedMeasurementData);
          })
        }
        height="85%"
        data={measurement || []}
        width={measurementChartWidth}
        chartConfig={customChartConfig}
        yConfig={{ domain: domainByGlobalPreferences }}
      />
    </MeasurementContainer>
  );
}
