import * as HoverCard from "@radix-ui/react-hover-card";
import { useMemo } from "react";

import { ArtiQAccepted } from "../../../../../../assets/icons/ArtiQAccepted";
import { ArtiQRejected } from "../../../../../../assets/icons/ArtiQRejected";
import { ArtiQUsableAccepted } from "../../../../../../assets/icons/ArtiQUsableAccepted";
import { artiQAcceptabilityStatus } from "../../../../../../constants/spirometryConstants";
import { SpirometryListTitle } from "../../MeasurementModal.styles";
import {
  ListBlowHoverCardContent,
  ListBlowItem,
} from "./BlowAcceptabilitySection.styles";

type BlowAcceptabilityProps = {
  fvcAcceptabilityReason?: string[];
  fev1Acceptability?: string;
  fvcAcceptability?: string;
};

const criteriasConstants = {
  EOFE_FALSE: "Failure of EOFE criteria",
  BEV_FALSE_1: "Excessive hesitation causing failure of BEV criteria",
  BEV_FALSE_2: "Artefact (P=",
};

export function BlowAcceptabilitySection({
  fev1Acceptability,
  fvcAcceptability,
  fvcAcceptabilityReason,
}: BlowAcceptabilityProps) {
  const acceptabilityCriteria = useMemo(() => {
    const criteria = {
      EOFE: "True",
      BEV: "True",
    };

    const acceptabilityReason = fvcAcceptabilityReason;

    if (!acceptabilityReason) {
      criteria.BEV = "-";
      criteria.EOFE = "-";

      return criteria;
    }

    if (
      acceptabilityReason.some((reason: string) =>
        reason.includes(criteriasConstants.EOFE_FALSE)
      )
    ) {
      criteria.EOFE = "False";
    }

    if (
      acceptabilityReason.some((reason: string) =>
        reason.includes(criteriasConstants.BEV_FALSE_1)
      ) ||
      acceptabilityReason.some((reason: string) =>
        reason.includes(criteriasConstants.BEV_FALSE_2)
      )
    ) {
      criteria.BEV = "False";
    }

    return criteria;
  }, [fvcAcceptabilityReason]);

  const blowStatus = useMemo(() => {
    if (!fev1Acceptability || !fvcAcceptability)
      return { icon: null, status: "-" };

    if (
      fev1Acceptability === artiQAcceptabilityStatus.ACCEPTABLE &&
      fvcAcceptability === artiQAcceptabilityStatus.ACCEPTABLE
    ) {
      return { icon: <ArtiQAccepted />, status: "Accepted" };
    }

    if (
      (fev1Acceptability === artiQAcceptabilityStatus.REJECTED &&
        fvcAcceptability === artiQAcceptabilityStatus.ACCEPTABLE) ||
      (fev1Acceptability === artiQAcceptabilityStatus.REJECTED &&
        fvcAcceptability === artiQAcceptabilityStatus.USABLE) ||
      (fev1Acceptability === artiQAcceptabilityStatus.REJECTED &&
        fvcAcceptability === artiQAcceptabilityStatus.REJECTED)
    ) {
      return { icon: <ArtiQRejected />, status: "Rejected" };
    }

    return { icon: <ArtiQUsableAccepted />, status: "Usable" };
  }, [fev1Acceptability, fvcAcceptability]);

  return (
    <div>
      <SpirometryListTitle>Accept.</SpirometryListTitle>

      <HoverCard.Root>
        <HoverCard.Trigger>
          <ListBlowItem>
            <p>Blow 1</p>

            {blowStatus.icon}
          </ListBlowItem>
        </HoverCard.Trigger>

        <ListBlowHoverCardContent side="bottom" align="start">
          <p>Status: {blowStatus.status}</p>
          <p>BEV criteria: {acceptabilityCriteria.BEV}</p>
          <p>EOFE criteria: {acceptabilityCriteria.EOFE}</p>
        </ListBlowHoverCardContent>
      </HoverCard.Root>
    </div>
  );
}
