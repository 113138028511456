import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { queryClient } from "../../config/query";
import { usePatientInfo } from "../../hooks/queries/patients";
import { PageLayout } from "../../layout/components/PageLayout";
import { mixpanelActions } from "../../utils/mixpanel";
import { scrollToSectionPatientPage } from "../../utils/scrollToSectionPatientPage";
import { AlertsSection } from "./components/AlertsSection";
import { LabsSection } from "./components/LabsSection";
import { MeasurementSection } from "./components/MeasurementsSection";
import { PatientLateralMenuDesktop } from "./components/PatientLateralMenu";
import { PatientSubNav } from "./components/PatientSubNav";
import { SpirometrySection } from "./components/SpirometrySection";
import {
  LoadingOverlay,
  PatientPageContainer,
  PatientPageContent,
  StyledSection,
} from "./PatientPage.styles";

import "react-spring-bottom-sheet/dist/style.css";

export function PatientPage() {
  const location = useLocation();
  const { patientId } = useParams();

  const isFirstRender = useRef(true);

  const overviewSectionRef = useRef<null | HTMLDivElement>(null);
  const measurementsSectionRef = useRef<null | HTMLDivElement>(null);
  const spirometrySectionRef = useRef<null | HTMLDivElement>(null);
  const labsSectionRef = useRef<null | HTMLDivElement>(null);
  const notesSectionRef = useRef<null | HTMLDivElement>(null);
  const medicationSectionRef = useRef<null | HTMLDivElement>(null);
  const surveysAndJournalSectionRef = useRef<null | HTMLDivElement>(null);
  const alertsSectionRef = useRef<null | HTMLDivElement>(null);

  const { patient, isFetching } = usePatientInfo({ patientId });
  const [isPatientLateralMenuOpen, setIsPatientLateralMenuOpen] =
    useState(false);

  document.title = `Patient Page - patientMpower`;

  useEffect(() => {
    mixpanelActions.track("Visit Screen: Patient Page");

    return () => {
      queryClient.invalidateQueries([
        `patient-info-${patientId}`,
        `patient-monitoring-info-${patientId}`,
      ]);
    };
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    scrollToSectionPatientPage({
      overviewSectionRef,
      measurementsSectionRef,
      spirometrySectionRef,
      labsSectionRef,
      notesSectionRef,
      medicationSectionRef,
      surveysAndJournalSectionRef,
      alertsSectionRef,
    });
  }, [location]);

  const handleOnClickToExpandLateralMenu = () => {
    setIsPatientLateralMenuOpen((prevState) => !prevState);
  };

  if (isFetching) return <LoadingOverlay />;

  return (
    <PatientPageContainer>
      <PatientLateralMenuDesktop
        patientId={patientId}
        open={isPatientLateralMenuOpen}
        onExpandClick={handleOnClickToExpandLateralMenu}
      />

      <PatientPageContent
        isLateralPatientMenuOpenOnDesktop={isPatientLateralMenuOpen}
      >
        <PatientSubNav
          isLateralPatientMenuOpenOnDesktop={isPatientLateralMenuOpen}
        />

        <PageLayout>
          <StyledSection
            ref={spirometrySectionRef}
            css={{ paddingTop: 48, "@sm": { paddingTop: 0 } }}
          >
            <SpirometrySection {...{ patient }} />
          </StyledSection>

          <StyledSection ref={measurementsSectionRef}>
            <MeasurementSection {...{ patient }} />
          </StyledSection>

          <StyledSection ref={labsSectionRef}>
            <LabsSection {...{ patient }} />
          </StyledSection>

          <StyledSection
            css={{ "@sm": { marginBottom: 90 } }}
            ref={alertsSectionRef}
          >
            <AlertsSection />
          </StyledSection>
        </PageLayout>
      </PatientPageContent>
    </PatientPageContainer>
  );
}
