export function Pill({ ...rest }) {
  return (
    <svg {...rest} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="22" height="22" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.24998 10.0833V6.41666C8.24998 4.90416 9.48748 3.66666 11 3.66666C12.5125 3.66666 13.75 4.90416 13.75 6.41666V10.0833H8.24998ZM15.5833 10.0833V11.9167V15.5833C15.5833 18.1133 13.53 20.1667 11 20.1667C8.46998 20.1667 6.41665 18.1133 6.41665 15.5833V11.9167V10.0833V6.41666C6.41665 3.88666 8.46998 1.83333 11 1.83333C13.53 1.83333 15.5833 3.88666 15.5833 6.41666V10.0833Z"
      />
    </svg>
  );
}
