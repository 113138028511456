import { AxiosResponse } from "axios";

import {
  GetPatientMeasurementProps,
  IPatientMeasurementAPIResponse,
} from "../@types/Measurements";
import { api } from "./api";

export const measurementsService = {
  async getPatientMeasurement({
    type,
    toDate,
    fromDate,
    patientId,
  }: GetPatientMeasurementProps): Promise<
    AxiosResponse<IPatientMeasurementAPIResponse[]>
  > {
    const url = `/api/patient_measurement/`;

    const patientMeasurementsResponse = await api.get<
      IPatientMeasurementAPIResponse[]
    >(url, {
      params: {
        field: type,
        patient_id: patientId,
        from_date: fromDate,
        to_date: toDate,
      },
    });

    return patientMeasurementsResponse;
  },
};
