import React from "react";

type ScrollToSectionPatientPageProps = {
  overviewSectionRef: React.MutableRefObject<null | HTMLElement>;
  measurementsSectionRef: React.MutableRefObject<null | HTMLElement>;
  spirometrySectionRef: React.MutableRefObject<null | HTMLElement>;
  labsSectionRef: React.MutableRefObject<null | HTMLElement>;
  notesSectionRef: React.MutableRefObject<null | HTMLElement>;
  medicationSectionRef: React.MutableRefObject<null | HTMLElement>;
  surveysAndJournalSectionRef: React.MutableRefObject<null | HTMLElement>;
  alertsSectionRef: React.MutableRefObject<null | HTMLElement>;
};

export function scrollToSectionPatientPage({
  overviewSectionRef,
  measurementsSectionRef,
  spirometrySectionRef,
  labsSectionRef,
  notesSectionRef,
  medicationSectionRef,
  surveysAndJournalSectionRef,
  alertsSectionRef,
}: ScrollToSectionPatientPageProps) {
  const { pathname } = window.location;

  if (pathname.includes("overview") && overviewSectionRef.current) {
    overviewSectionRef.current.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }

  if (pathname.includes("measurements") && measurementsSectionRef.current) {
    measurementsSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("spirometry") && spirometrySectionRef.current) {
    spirometrySectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("labs") && labsSectionRef.current) {
    labsSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("notes") && notesSectionRef.current) {
    notesSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("medication") && medicationSectionRef.current) {
    medicationSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (pathname.includes("alerts") && alertsSectionRef.current) {
    alertsSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  if (
    pathname.includes("surveys-journal") &&
    surveysAndJournalSectionRef.current
  ) {
    surveysAndJournalSectionRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }
}
