/* eslint-disable jsx-a11y/control-has-associated-label */
import { StyledTable, TableContainer } from "./SpirometryValuesSection.styles";

type SpirometryValuesSectionProps = {
  bestValues?: {
    fvc: number | string;
    pef: number | string;
    fev1: number | string;
    fev1fvc: number | string;
  };
};

export function SpirometryValuesSection({
  bestValues,
}: SpirometryValuesSectionProps) {
  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <tr>
            <th />
            <th>Best</th>
            <th>% Pred</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>FVC (L)</td>
            <td>{bestValues?.fvc}</td>
            <td>-</td>
          </tr>
          <tr>
            <td>FEV1 (L)</td>
            <td>{bestValues?.fev1}</td>
            <td>-</td>
          </tr>
          <tr>
            <td>FEV1/FVC (%)</td>
            <td>{bestValues?.fev1fvc}</td>
            <td>-</td>
          </tr>
          <tr>
            <td>PEF (L/s)</td>
            <td>{bestValues?.pef}</td>
            <td>-</td>
          </tr>
        </tbody>
      </StyledTable>
    </TableContainer>
  );
}
