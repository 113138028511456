import { useParams } from "react-router-dom";

import { MeasurementTypes } from "../../../../@types/Measurements";
import { ChartCard } from "../../../../components/ChartCard";
import { ChartConfig } from "../../../../components/ChartCard/ChartCard.types";
import { useMeasurement } from "../../../../hooks/queries/measurements";
import { useModal } from "../../../../hooks/useModal";
import { MeasurementModal } from "../MeasurementModal";

type MeasurementCardProps = {
  measurementType: MeasurementTypes;
  cardTitle: string;
  chartConfig: ChartConfig[];
};

export function MeasurementCard({
  cardTitle,
  chartConfig,
  measurementType,
}: MeasurementCardProps) {
  const { openModal } = useModal();
  const { patientId } = useParams();

  const { measurement, isInitialLoading, mainMeasurementValues } =
    useMeasurement({
      type: measurementType,
      patientId,
    });

  const onClickToOpenMeasurement = () => {
    openModal(
      <MeasurementModal
        patientId={patientId}
        cardTitle={cardTitle}
        chartConfig={chartConfig}
        measurementType={measurementType}
      />,
      { width: "auto" }
    );
  };

  return (
    <ChartCard
      title={cardTitle}
      data={measurement || []}
      chartConfig={chartConfig}
      isLoading={isInitialLoading}
      onClick={onClickToOpenMeasurement}
      specificValues={[
        { label: "Average", value: mainMeasurementValues?.avg || "-" },
        { label: "Highest", value: mainMeasurementValues?.max || "-" },
        { label: "Lowest", value: mainMeasurementValues?.min || "-" },
      ]}
    />
  );
}
